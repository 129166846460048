import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import { genderOptions } from '../../../common/constants';
import MultiSelect from '../../../components/Inputs/MultiSelect';
import {
  ClientCountDocument,
  ClientsDocument,
  useInsertClientMutation,
} from '../../../api/graphql';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import BackButton from '../../../components/BackButton';

interface ClientInsertForm {
  first_name: string;
  last_name: string;
  gender: any;
  email: string;
  display_name: string;
  location: string;
  appointment_type_preference?: any; // TODO: Come back to this
}

const ClientInsertSchema: yup.SchemaOf<ClientInsertForm> = yup.object({
  display_name: yup.string().min(3).required().label('Display Name'),
  email: yup.string().email().required().label('Email'),
  first_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .required()
    .label('First Name'),
  last_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .required()
    .label('Last Name'),
  gender: yup
    .array()
    .of(
      yup
        .string()
        .oneOf([
          'Male',
          'Female',
          'Nonbinary',
          'Genderqueer',
          'Agender',
          'Prefer not to say',
        ])
    )
    .nullable()
    .label('Gender'),
  location: yup.string().min(6).required().label('Location'),
  appointment_type_preference: yup
    .array()
    .of(yup.string().required())
    .nullable(),
});

const CreateClient: React.FC = () => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm<ClientInsertForm>({
    resolver: yupResolver(ClientInsertSchema),
    defaultValues: { appointment_type_preference: [], gender: [] },
    mode: 'onChange',
  });
  const [insertClientRequest, { loading }] = useInsertClientMutation({
    onError: () =>
      SnackbarManager.addSnackbar({
        message: 'Something went wrong',
        options: { variant: 'error' },
      }),
    onCompleted: () => {
      SnackbarManager.addSnackbar({
        message: 'Client successfully added',
        options: { variant: 'success' },
      });
      navigate('/dashboard/clients');
    },
    refetchQueries: [
      ClientsDocument,
      'clients',
      ClientCountDocument,
      'clientCount',
    ],
  });

  if (loading) return <Loader />;
  const insertClient = (data: ClientInsertForm) => {
    insertClientRequest({
      variables: {
        password: 'ajkalsfdkldasjflkdsaj', // solve this issue
        ...data,
      },
    });
  };

  return (
    <Box component="main">
      <BackButton />

      <Box component="form" onSubmit={handleSubmit(insertClient)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h4">Insert Client</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="first_name"
            control={control}
            margin="normal"
            id="first_name"
            label="First name"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="last_name"
            control={control}
            margin="normal"
            id="last_name"
            label="Last Name"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="display_name"
            control={control}
            margin="normal"
            id="display_name"
            label="Display Name"
            error={!!errors.display_name}
            helperText={errors.display_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="email"
            control={control}
            margin="normal"
            id="email"
            label="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="location"
            control={control}
            margin="normal"
            id="location"
            label="Location"
            error={!!errors.location}
            helperText={errors.location?.message}
            sx={{ flexGrow: '1' }}
          />
          <MultiSelect
            name="gender"
            label="Gender"
            control={control}
            options={genderOptions}
            error={!!errors.gender}
            helperText={errors.gender?.message}
            sx={{ marginTop: 1 }}
          />
          <MultiSelect
            name="appointment_type_preference"
            label=" Appointment Preference"
            control={control}
            options={['In Call', 'Out Call', 'Virtual']}
            error={!!errors.appointment_type_preference}
            helperText={errors.appointment_type_preference?.message}
            sx={{ marginTop: 1 }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || !isDirty}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateClient;
