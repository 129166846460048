import React, { useState } from 'react';
import { Box, Button, InputLabel } from '@mui/material';

export type Image = {
  id: string;
  key: string;
  url: string;
};

type ImagesForApprovalProps = {
  images: Image[];
  // setImagesArray: React.Dispatch<React.SetStateAction<Image[]>>;
  setImagesToDelete: React.Dispatch<React.SetStateAction<string[]>>;
};

function ImagesForApproval(props: ImagesForApprovalProps) {
  const { images, setImagesToDelete } = props;
  const [imagesArray, setImagesArray] = useState([...images]);

  const handleDelete = async (key: string) => {
    setImagesArray((prev) => prev.filter((image) => image.key !== key));
    setImagesToDelete((prev) => [...prev, key]);
  };

  return (
    <>
      <InputLabel
        id={`field-label-${'Pictures'}`}
        style={{ marginLeft: '-10px' }}
      >
        Pictures To Approve
      </InputLabel>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {imagesArray.map((image: any) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 2,
              }}
              key={image.key}
            >
              <Box
                component="img"
                sx={{
                  maxHeight: 200,
                }}
                alt="user image"
                src={image?.url}
              />
              <Button onClick={() => handleDelete(image.key)}>Delete</Button>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default ImagesForApproval;
