import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import {
  ClientCountDocument,
  ClientsDocument,
  useClientQuery,
  useDeleteClientByIdMutation,
  useUpdateClientByIdMutation,
} from '../../../api/graphql';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { AppSelect } from '../../../components/Inputs/SelectField';
import {
  activeStatusOptions,
  genderOptions,
  verificationStatusOptions,
} from '../../../common/constants';
import { Nullable } from '../../../common/types';
import MultiSelect from '../../../components/Inputs/MultiSelect';
import BackButton from '../../../components/BackButton';

interface ClientEditForm {
  id: number;
  display_name: string;
  email: string;
  first_name?: Nullable<string>;
  gender: any;
  last_name?: Nullable<string>;
  profile_status: string;
  location: string;
  user_status: string;
  appointment_type_preference?: any; // TODO: Come back to this
}

const ClientEditSchema: yup.SchemaOf<ClientEditForm> = yup.object({
  id: yup.number().integer().required(),
  display_name: yup.string().min(3).required().label('Display Name'),
  email: yup.string().email().required().label('Email'),
  first_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .nullable()
    .label('First Name'),
  last_name: yup
    .string()
    .matches(/.{3,}/, {
      excludeEmptyString: true,
      message: 'Must be 3 characters long',
    })
    .nullable()
    .label('Last Name'),
  gender: yup
    .array()
    .of(
      yup
        .string()
        .oneOf([
          'Male',
          'Female',
          'Nonbinary',
          'Genderqueer',
          'Agender',
          'Prefer not to say',
        ])
    )
    .nullable()
    .label('Gender'),
  profile_status: yup
    .string()
    .oneOf(['Active', 'Inactive'])
    .required()
    .label('Profile Status'),
  location: yup.string().min(6).required().label('Location'),
  user_status: yup
    .string()
    .oneOf(['Verified', 'Unverified'])
    .required()
    .label('Status'),
  appointment_type_preference: yup
    .array()
    .of(yup.string().required())
    .nullable(),
});

const constructModalBody = (client?: any) =>
  `Are you sure you want to remove the client with name ${client?.profile.owner.display_name}`;

const EditClient: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: client, loading } = useClientQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
  } = useForm<ClientEditForm>({
    resolver: yupResolver(ClientEditSchema),
    defaultValues: { appointment_type_preference: [], gender: [] },
    mode: 'onChange',
  });

  const [updateClientByIdRequest, { loading: updateLoading }] =
    useUpdateClientByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Client successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [deleteClientRequest, { loading: deleteClientByIdLoading }] =
    useDeleteClientByIdMutation({
      onCompleted: () => {
        setIsRemovingItem(false);
        SnackbarManager.addSnackbar({
          message: `Client with ID ${client?.client_by_pk?.id} successfully deleted`,
          options: { variant: 'success' },
        });
        navigate('/dashboard/clients');
      },
      onError: () => {
        setIsRemovingItem(false);
      },
      refetchQueries: [
        ClientsDocument,
        'clients',
        ClientCountDocument,
        'clientCount',
      ],
    });

  useEffect(() => {
    if (client && client.client_by_pk) {
      const defaultValues = {
        id: client.client_by_pk.id,
        created_at: format(
          parseISO(client.client_by_pk.created_at),
          'MM-dd-yyyy'
        ),
        updated_at: format(
          parseISO(client.client_by_pk.updated_at),
          'MM-dd-yyyy'
        ),
        display_name: client.client_by_pk.profile.owner.display_name,
        email: client.client_by_pk.profile.owner.email,
        gender: client.client_by_pk.profile.owner.gender,
        location: client.client_by_pk.profile.location,
        first_name: client.client_by_pk.profile.owner.first_name,
        last_name: client.client_by_pk.profile.owner.last_name,
        profile_status: client.client_by_pk.profile.status,
        user_status: client.client_by_pk.profile.owner.status,
        appointment_type_preference:
          client.client_by_pk.appointment_type_preference ?? [],
      };
      reset(defaultValues);
    }
  }, [client?.client_by_pk, reset]);

  const updateClient = (formData: ClientEditForm) => {
    updateClientByIdRequest({
      variables: formData,
    });
  };

  const promptClientDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deleteClientRequest({ variables: { id: client!.client_by_pk!.id } });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <BackButton />

      <Box component="form" onSubmit={handleSubmit(updateClient)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="display_name"
            control={control}
            margin="normal"
            id="display_name"
            label="Display Name"
            error={!!errors.display_name}
            helperText={errors.display_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="email"
            control={control}
            margin="normal"
            id="email"
            label="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="first_name"
            control={control}
            margin="normal"
            id="first_name"
            label="First name"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="last_name"
            control={control}
            margin="normal"
            id="last_name"
            label="Last Name"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="location"
            control={control}
            margin="normal"
            id="location"
            label="Location"
            error={!!errors.location}
            helperText={errors.location?.message}
            sx={{ flexGrow: '1' }}
          />
          <AppSelect<ClientEditForm, typeof activeStatusOptions>
            name="profile_status"
            label="Profile Status"
            control={control}
            options={activeStatusOptions}
            error={!!errors.profile_status}
            helperText={errors.profile_status?.message}
            sx={{ marginTop: 1 }}
          />
          <AppSelect<ClientEditForm, typeof verificationStatusOptions>
            name="user_status"
            label="User Status"
            control={control}
            options={verificationStatusOptions}
            error={!!errors.user_status}
            helperText={errors.user_status?.message}
            sx={{ marginTop: 1 }}
          />
          <MultiSelect
            name="gender"
            label="Gender"
            control={control}
            options={genderOptions}
            error={!!errors.gender}
            helperText={errors.gender?.message}
            sx={{ marginTop: 1 }}
          />
          <MultiSelect
            name="appointment_type_preference"
            label=" Appointment Preference"
            control={control}
            options={['In Call', 'Out Call', 'Virtual']}
            error={!!errors.appointment_type_preference}
            helperText={errors.appointment_type_preference?.message}
            sx={{ marginTop: 1 }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || !isDirty}
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptClientDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteClientByIdLoading}
          content={{
            body: constructModalBody(client!.client_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Client',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditClient;
