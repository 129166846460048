import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  SkillCountDocument,
  SkillsDocument,
  useDeleteSkillByIdMutation,
  useSkillQuery,
  useUpdateSkillByIdMutation,
} from '../../../api/graphql';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { AppSelect } from '../../../components/Inputs/SelectField';
import { verificationStatusOptions } from '../../../common/constants';
import BackButton from '../../../components/BackButton';

interface SkillEditForm {
  id: number;
  name: string;
  status: string;
}

const SkillEditSchema: yup.SchemaOf<SkillEditForm> = yup.object({
  id: yup.number().integer().required(),
  name: yup.string().min(3).required(),
  status: yup.string().oneOf(['Verified', 'Unverified']).required(),
});

const constructModalBody = (skill?: any) =>
  `Are you sure you want to remove the skill with title ${skill?.title}`;

const EditSkill: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: skill, loading } = useSkillQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
  } = useForm<SkillEditForm>({
    resolver: yupResolver(SkillEditSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      status: 'Verified',
    },
  });

  const [updateSkillByIdRequest, { loading: updateLoading }] =
    useUpdateSkillByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Skill successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [deleteSkillRequest, { loading: deleteSkillByIdLoading }] =
    useDeleteSkillByIdMutation({
      onCompleted: () => {
        setIsRemovingItem(false);
        SnackbarManager.addSnackbar({
          message: `Skill with ID ${skill?.skill_by_pk?.id} successfully deleted`,
          options: { variant: 'success' },
        });
        navigate('/dashboard/skills');
      },
      onError: () => {
        setIsRemovingItem(false);
      },
      refetchQueries: [
        SkillsDocument,
        'skills',
        SkillCountDocument,
        'skillCount',
      ],
    });

  useEffect(() => {
    if (skill) {
      reset(skill.skill_by_pk || {});
    }
  }, [skill?.skill_by_pk, reset]);

  const updateSkill = (formData: SkillEditForm) => {
    updateSkillByIdRequest({
      variables: formData,
    });
  };

  const promptSkillDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deleteSkillRequest({ variables: { id: skill!.skill_by_pk!.id } });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <BackButton />

      <Box component="form" onSubmit={handleSubmit(updateSkill)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="name"
            control={control}
            margin="normal"
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={{ flexGrow: '1' }}
          />
          <AppSelect<SkillEditForm, typeof verificationStatusOptions>
            name="status"
            label="Status"
            control={control}
            options={verificationStatusOptions}
            error={!!errors.status}
            helperText={errors.status?.message}
            sx={{ marginTop: 1 }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || !isDirty}
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptSkillDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteSkillByIdLoading}
          content={{
            body: constructModalBody(skill!.skill_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Skill',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditSkill;
