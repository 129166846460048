import React from 'react';
import * as yup from 'yup';
import { Box, Button, Backdrop, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import TextField from '../../../components/Inputs/TextField';
import { useCreateSkillMutation } from '../../../api/graphql';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { verificationStatusOptions } from '../../../common/constants';
import { AppSelect } from '../../../components/Inputs/SelectField';
import BackButton from '../../../components/BackButton';

interface CreateSkillForm {
  name: string;
  status: string;
}

const SkillCreateSchema: yup.SchemaOf<CreateSkillForm> = yup.object({
  name: yup.string().min(3).required(),
  status: yup.string().oneOf(['Verified', 'Unverified']).required(),
});

const CreateSkill: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateSkillForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      status: 'Verified',
    },
    resolver: yupResolver(SkillCreateSchema),
  });
  const navigate = useNavigate();
  const [createSkillRequest, { loading }] = useCreateSkillMutation({
    onCompleted: () => {
      SnackbarManager.addSnackbar({
        message: 'Skill entry successfully added',
        options: { variant: 'success' },
      });
      navigate('/dashboard/skills');
    },
  });

  const createSkill = (values: CreateSkillForm) => {
    createSkillRequest({
      variables: values,
    });
  };

  return (
    <Box component="main">
      <BackButton />

      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Create skill entry
      </Typography>
      <Box component="form" onSubmit={handleSubmit(createSkill)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="name"
            control={control}
            margin="normal"
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={{ flexGrow: '1' }}
          />
          <AppSelect<CreateSkillForm, typeof verificationStatusOptions>
            name="status"
            label="Status"
            control={control}
            options={verificationStatusOptions}
            error={!!errors.status}
            helperText={errors.status?.message}
            sx={{ marginTop: 1 }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || !isDirty}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
          {loading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSkill;
