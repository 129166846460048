import React from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import * as yup from 'yup';
import { Box, Button, Backdrop, Typography } from '@mui/material';
import { useForm, useWatch, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import RichTextField from '../../../components/Inputs/RichTextField';
import Loader from '../../../components/Loader';
import TextField from '../../../components/Inputs/TextField';
import { useCreatePressMutation } from '../../../api/graphql';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { Nullable } from '../../../common/types';
import BackButton from '../../../components/BackButton';

interface CreatePressForm {
  title: string;
  body: object;
  show_more_text?: Nullable<string>;
  show_more_url?: Nullable<string>;
  video?: Nullable<string>;
  audio?: Nullable<string>;
}

const PressCreateSchema: yup.SchemaOf<CreatePressForm> = yup.object({
  title: yup.string().min(4).required(),
  body: yup
    .object()
    .test({
      test: (value: any) => {
        if (value) {
          return value.getCurrentContent().getPlainText().length >= 140;
        }
        return false;
      },
      message: 'Body must be longer then 140 characters',
    })
    .required()
    .label('Body'),
  show_more_text: yup.string().required(),
  show_more_url: yup.string().url().label('Show more URL').required(),
  video: yup.string(),
  audio: yup.string(),
});

const CreatePress: React.FC = () => {
  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid },
  } = useForm<CreatePressForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      body: EditorState.createEmpty(),
    },
    resolver: yupResolver(PressCreateSchema),
  });
  const navigate = useNavigate();
  const [createPressRequest, { loading }] = useCreatePressMutation({
    onCompleted: () => {
      SnackbarManager.addSnackbar({
        message: 'Press entry successfully added',
        options: { variant: 'success' },
      });
      navigate('/dashboard/press');
    },
  });

  const { body } = useWatch({ control });
  const isEditorDirty =
    JSON.stringify(convertToRaw((body as EditorState).getCurrentContent())) !==
    JSON.stringify(EditorState.createEmpty());

  const { isDirty: isTitleDirty } = getFieldState('title');

  const createPress = (values: CreatePressForm) => {
    createPressRequest({
      variables: {
        ...values,
        body: JSON.stringify(
          convertToRaw((values.body as EditorState).getCurrentContent())
        ),
      },
    });
  };

  return (
    <Box component="main">
      <BackButton />

      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Create press entry
      </Typography>
      <Box component="form" onSubmit={handleSubmit(createPress)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
            required
          />
          <RichTextField
            name="body"
            label="Body"
            error={!!errors.body}
            helperText={(errors.body as FieldError)?.message}
            control={control}
            required
          />
          <TextField
            name="show_more_text"
            control={control}
            margin="normal"
            id="show_more_text"
            label="Show More Text"
            error={!!errors.show_more_text}
            helperText={errors.show_more_text?.message}
            sx={{ flexGrow: '1' }}
            required
          />
          <TextField
            name="show_more_url"
            control={control}
            margin="normal"
            id="show_more_url"
            label="Show More URL"
            error={!!errors.show_more_url}
            helperText={errors.show_more_url?.message}
            sx={{ flexGrow: '1' }}
            required
          />

          <TextField
            name="video"
            control={control}
            margin="normal"
            id="video"
            label="Video"
            error={!!errors.video}
            helperText={errors.video?.message}
            sx={{ flexGrow: '1' }}
          />

          <TextField
            name="audio"
            control={control}
            margin="normal"
            id="audio"
            label="Audio"
            error={!!errors.audio}
            helperText={errors.audio?.message}
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || (!isEditorDirty && !isTitleDirty)}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
          {loading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePress;
