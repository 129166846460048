import React, { useContext } from 'react';
import * as yup from 'yup';
import { Box, Button, Backdrop, Typography } from '@mui/material';
import { useForm, FieldError, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { convertToRaw, EditorState } from 'draft-js';
import Loader from '../../../components/Loader';
import TextField from '../../../components/Inputs/TextField';
import { useCreateAnnouncementMutation } from '../../../api/graphql';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import { UserContext } from '../../../contexts/user.context';
import RichTextField from '../../../components/Inputs/RichTextField';
import {
  AppSelect as SelectField,
  AppSelectOption,
} from '../../../components/Inputs/SelectField';
import BackButton from '../../../components/BackButton';

interface CreateAnnouncementForm {
  title: string;
  audience: string;
  message: object;
}

const AnnouncementCreateSchema: yup.SchemaOf<CreateAnnouncementForm> =
  yup.object({
    title: yup.string().min(3).required(),
    audience: yup.string().oneOf(['practitioner', 'client', 'all']).required(),
    message: yup.object().required().label('Body'),
  });

const audienceSelectOptions: AppSelectOption[] = [
  {
    label: 'Practitioner',
    value: 'practitioner',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'All',
    value: 'all',
  },
];

const CreateAnnouncement: React.FC = () => {
  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid },
  } = useForm<CreateAnnouncementForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      message: EditorState.createEmpty(),
      audience: 'practitioner',
    },
    resolver: yupResolver(AnnouncementCreateSchema),
  });
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [createAnnouncementRequest, { loading }] =
    useCreateAnnouncementMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Announcement entry successfully added',
          options: { variant: 'success' },
        });
        navigate('/dashboard/announcements');
      },
    });

  const { message } = useWatch({ control });
  const isEditorDirty =
    JSON.stringify(
      convertToRaw((message as EditorState).getCurrentContent())
    ) !== JSON.stringify(EditorState.createEmpty());

  const { isDirty: isTitleDirty } = getFieldState('title');

  const createAnnouncement = (values: CreateAnnouncementForm) => {
    createAnnouncementRequest({
      variables: {
        ...values,
        message: JSON.stringify(
          convertToRaw((values.message as EditorState).getCurrentContent())
        ),
        authorId: user.user!.id,
      },
    });
  };

  return (
    <Box component="main">
      <BackButton />

      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Create announcement entry
      </Typography>
      <Box component="form" onSubmit={handleSubmit(createAnnouncement)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
            required
          />
          <RichTextField
            name="message"
            label="Message"
            error={!!errors.message}
            helperText={(errors.message as FieldError)?.message}
            control={control}
            required
          />
          <SelectField<CreateAnnouncementForm, typeof audienceSelectOptions>
            name="audience"
            label="Audience"
            control={control}
            error={!!errors.audience}
            options={audienceSelectOptions}
            helperText={errors.audience?.message}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || (!isEditorDirty && !isTitleDirty)}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
          {loading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAnnouncement;
