import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon />}
        type="submit"
        variant="contained"
        sx={{ flexGrow: 1, mb: 1 }}
        onClick={handleBack}
      >
        Back
      </Button>
    </div>
  );
};

export default BackButton;
