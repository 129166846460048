import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
};

export type ApplyTrainingPaymentCouponInput = {
  couponId: Scalars['String'];
  orderId: Scalars['String'];
};

export type AuthTokens = {
  __typename?: 'AuthTokens';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: PartialUser;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateEnrollmentInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  heardAboutUsAt?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  orderId: Scalars['String'];
  primaryPhoneNumber?: InputMaybe<Scalars['Float']>;
  stateOrProvince: Scalars['String'];
  zip?: InputMaybe<Scalars['String']>;
};

export type EnrollmentGql = {
  __typename?: 'EnrollmentGQL';
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  heardAboutUsAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName: Scalars['String'];
  primaryPhoneNumber?: Maybe<Scalars['Float']>;
  stateOrProvince: Scalars['String'];
  zip?: Maybe<Scalars['String']>;
};

export enum Gender {
  Agender = 'Agender',
  Female = 'Female',
  Genderqueer = 'Genderqueer',
  Male = 'Male',
  Nonbinary = 'Nonbinary',
  PreferNotToSay = 'PreferNotToSay'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type PartialUser = {
  __typename?: 'PartialUser';
  announcements_last_seen_at: Scalars['DateTime'];
  display_name: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  gender: Array<Scalars['String']>;
  id: Scalars['Int'];
  last_logged_in_at?: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  profile_picture?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};

export type PublicFile = {
  __typename?: 'PublicFile';
  key: Scalars['String'];
  url: Scalars['String'];
};

export enum RegisteredUserType {
  Client = 'Client',
  Practitioner = 'Practitioner'
}

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['Int'];
};

export type SignUpInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  gender: Array<Gender>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  type: RegisteredUserType;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Success = {
  __typename?: 'Success';
  result: Scalars['Boolean'];
};

export type TrainingOrderCouponUpdate = {
  __typename?: 'TrainingOrderCouponUpdate';
  total: Scalars['Int'];
};

export type TrainingOrderDetails = {
  __typename?: 'TrainingOrderDetails';
  orderClientSecret: Scalars['String'];
  orderId: Scalars['String'];
};

export type ValidateEnrollmentInput = {
  orderId: Scalars['String'];
};

export type VerifyUserInput = {
  token: Scalars['String'];
  userId: Scalars['Int'];
};

/** columns and relationships of "announcement" */
export type Announcement = {
  __typename?: 'announcement';
  audience: Scalars['String'];
  /** An object relationship */
  author: User;
  created_at: Scalars['timestamptz'];
  created_by_id: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "announcement" */
export type Announcement_Aggregate = {
  __typename?: 'announcement_aggregate';
  aggregate?: Maybe<Announcement_Aggregate_Fields>;
  nodes: Array<Announcement>;
};

/** aggregate fields of "announcement" */
export type Announcement_Aggregate_Fields = {
  __typename?: 'announcement_aggregate_fields';
  avg?: Maybe<Announcement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Announcement_Max_Fields>;
  min?: Maybe<Announcement_Min_Fields>;
  stddev?: Maybe<Announcement_Stddev_Fields>;
  stddev_pop?: Maybe<Announcement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Announcement_Stddev_Samp_Fields>;
  sum?: Maybe<Announcement_Sum_Fields>;
  var_pop?: Maybe<Announcement_Var_Pop_Fields>;
  var_samp?: Maybe<Announcement_Var_Samp_Fields>;
  variance?: Maybe<Announcement_Variance_Fields>;
};


/** aggregate fields of "announcement" */
export type Announcement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Announcement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Announcement_Avg_Fields = {
  __typename?: 'announcement_avg_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "announcement". All fields are combined with a logical 'AND'. */
export type Announcement_Bool_Exp = {
  _and?: InputMaybe<Array<Announcement_Bool_Exp>>;
  _not?: InputMaybe<Announcement_Bool_Exp>;
  _or?: InputMaybe<Array<Announcement_Bool_Exp>>;
  audience?: InputMaybe<String_Comparison_Exp>;
  author?: InputMaybe<User_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "announcement" */
export enum Announcement_Constraint {
  /** unique or primary key constraint */
  PkE0ef0550174fd1099a308fd18a0 = 'PK_e0ef0550174fd1099a308fd18a0'
}

/** input type for incrementing numeric columns in table "announcement" */
export type Announcement_Inc_Input = {
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "announcement" */
export type Announcement_Insert_Input = {
  audience?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Announcement_Max_Fields = {
  __typename?: 'announcement_max_fields';
  audience?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Announcement_Min_Fields = {
  __typename?: 'announcement_min_fields';
  audience?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "announcement" */
export type Announcement_Mutation_Response = {
  __typename?: 'announcement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Announcement>;
};

/** on_conflict condition type for table "announcement" */
export type Announcement_On_Conflict = {
  constraint: Announcement_Constraint;
  update_columns?: Array<Announcement_Update_Column>;
  where?: InputMaybe<Announcement_Bool_Exp>;
};

/** Ordering options when selecting data from "announcement". */
export type Announcement_Order_By = {
  audience?: InputMaybe<Order_By>;
  author?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: announcement */
export type Announcement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "announcement" */
export enum Announcement_Select_Column {
  /** column name */
  Audience = 'audience',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "announcement" */
export type Announcement_Set_Input = {
  audience?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Announcement_Stddev_Fields = {
  __typename?: 'announcement_stddev_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Announcement_Stddev_Pop_Fields = {
  __typename?: 'announcement_stddev_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Announcement_Stddev_Samp_Fields = {
  __typename?: 'announcement_stddev_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Announcement_Sum_Fields = {
  __typename?: 'announcement_sum_fields';
  created_by_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "announcement" */
export enum Announcement_Update_Column {
  /** column name */
  Audience = 'audience',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Announcement_Var_Pop_Fields = {
  __typename?: 'announcement_var_pop_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Announcement_Var_Samp_Fields = {
  __typename?: 'announcement_var_samp_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Announcement_Variance_Fields = {
  __typename?: 'announcement_variance_fields';
  created_by_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "blog" */
export type Blog = {
  __typename?: 'blog';
  /** An object relationship */
  author: User;
  author_id: Scalars['Int'];
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "blog" */
export type Blog_Aggregate = {
  __typename?: 'blog_aggregate';
  aggregate?: Maybe<Blog_Aggregate_Fields>;
  nodes: Array<Blog>;
};

/** aggregate fields of "blog" */
export type Blog_Aggregate_Fields = {
  __typename?: 'blog_aggregate_fields';
  avg?: Maybe<Blog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blog_Max_Fields>;
  min?: Maybe<Blog_Min_Fields>;
  stddev?: Maybe<Blog_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Sum_Fields>;
  var_pop?: Maybe<Blog_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Var_Samp_Fields>;
  variance?: Maybe<Blog_Variance_Fields>;
};


/** aggregate fields of "blog" */
export type Blog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog" */
export type Blog_Aggregate_Order_By = {
  avg?: InputMaybe<Blog_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Blog_Max_Order_By>;
  min?: InputMaybe<Blog_Min_Order_By>;
  stddev?: InputMaybe<Blog_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Blog_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Blog_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Blog_Sum_Order_By>;
  var_pop?: InputMaybe<Blog_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Blog_Var_Samp_Order_By>;
  variance?: InputMaybe<Blog_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blog" */
export type Blog_Arr_Rel_Insert_Input = {
  data: Array<Blog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Blog_On_Conflict>;
};

/** aggregate avg on columns */
export type Blog_Avg_Fields = {
  __typename?: 'blog_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blog" */
export type Blog_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
export type Blog_Bool_Exp = {
  _and?: InputMaybe<Array<Blog_Bool_Exp>>;
  _not?: InputMaybe<Blog_Bool_Exp>;
  _or?: InputMaybe<Array<Blog_Bool_Exp>>;
  author?: InputMaybe<User_Bool_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog" */
export enum Blog_Constraint {
  /** unique or primary key constraint */
  Pk_85c6532ad065a448e9de7638571 = 'PK_85c6532ad065a448e9de7638571'
}

/** input type for incrementing numeric columns in table "blog" */
export type Blog_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "blog" */
export type Blog_Insert_Input = {
  author?: InputMaybe<User_Obj_Rel_Insert_Input>;
  author_id?: InputMaybe<Scalars['Int']>;
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blog_Max_Fields = {
  __typename?: 'blog_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "blog" */
export type Blog_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Min_Fields = {
  __typename?: 'blog_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "blog" */
export type Blog_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "blog" */
export type Blog_Mutation_Response = {
  __typename?: 'blog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog>;
};

/** on_conflict condition type for table "blog" */
export type Blog_On_Conflict = {
  constraint: Blog_Constraint;
  update_columns?: Array<Blog_Update_Column>;
  where?: InputMaybe<Blog_Bool_Exp>;
};

/** Ordering options when selecting data from "blog". */
export type Blog_Order_By = {
  author?: InputMaybe<User_Order_By>;
  author_id?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blog */
export type Blog_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blog" */
export enum Blog_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blog" */
export type Blog_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>;
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Blog_Stddev_Fields = {
  __typename?: 'blog_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blog" */
export type Blog_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blog_Stddev_Pop_Fields = {
  __typename?: 'blog_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blog" */
export type Blog_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blog_Stddev_Samp_Fields = {
  __typename?: 'blog_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blog" */
export type Blog_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Blog_Sum_Fields = {
  __typename?: 'blog_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blog" */
export type Blog_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "blog" */
export enum Blog_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Blog_Var_Pop_Fields = {
  __typename?: 'blog_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blog" */
export type Blog_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blog_Var_Samp_Fields = {
  __typename?: 'blog_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blog" */
export type Blog_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Blog_Variance_Fields = {
  __typename?: 'blog_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blog" */
export type Blog_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "bookings" */
export type Bookings = {
  __typename?: 'bookings';
  answers: Scalars['jsonb'];
  /** An object relationship */
  client?: Maybe<User>;
  client_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  practitioner?: Maybe<User>;
  practitioner_id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "bookings" */
export type BookingsAnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "bookings" */
export type Bookings_Aggregate = {
  __typename?: 'bookings_aggregate';
  aggregate?: Maybe<Bookings_Aggregate_Fields>;
  nodes: Array<Bookings>;
};

/** aggregate fields of "bookings" */
export type Bookings_Aggregate_Fields = {
  __typename?: 'bookings_aggregate_fields';
  avg?: Maybe<Bookings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bookings_Max_Fields>;
  min?: Maybe<Bookings_Min_Fields>;
  stddev?: Maybe<Bookings_Stddev_Fields>;
  stddev_pop?: Maybe<Bookings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bookings_Stddev_Samp_Fields>;
  sum?: Maybe<Bookings_Sum_Fields>;
  var_pop?: Maybe<Bookings_Var_Pop_Fields>;
  var_samp?: Maybe<Bookings_Var_Samp_Fields>;
  variance?: Maybe<Bookings_Variance_Fields>;
};


/** aggregate fields of "bookings" */
export type Bookings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bookings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bookings" */
export type Bookings_Aggregate_Order_By = {
  avg?: InputMaybe<Bookings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bookings_Max_Order_By>;
  min?: InputMaybe<Bookings_Min_Order_By>;
  stddev?: InputMaybe<Bookings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bookings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bookings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bookings_Sum_Order_By>;
  var_pop?: InputMaybe<Bookings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bookings_Var_Samp_Order_By>;
  variance?: InputMaybe<Bookings_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Append_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bookings" */
export type Bookings_Arr_Rel_Insert_Input = {
  data: Array<Bookings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bookings_On_Conflict>;
};

/** aggregate avg on columns */
export type Bookings_Avg_Fields = {
  __typename?: 'bookings_avg_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bookings" */
export type Bookings_Avg_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bookings". All fields are combined with a logical 'AND'. */
export type Bookings_Bool_Exp = {
  _and?: InputMaybe<Array<Bookings_Bool_Exp>>;
  _not?: InputMaybe<Bookings_Bool_Exp>;
  _or?: InputMaybe<Array<Bookings_Bool_Exp>>;
  answers?: InputMaybe<Jsonb_Comparison_Exp>;
  client?: InputMaybe<User_Bool_Exp>;
  client_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  practitioner?: InputMaybe<User_Bool_Exp>;
  practitioner_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookings" */
export enum Bookings_Constraint {
  /** unique or primary key constraint */
  PkBee6805982cc1e248e94ce94957 = 'PK_bee6805982cc1e248e94ce94957'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bookings_Delete_At_Path_Input = {
  answers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bookings_Delete_Elem_Input = {
  answers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bookings_Delete_Key_Input = {
  answers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bookings" */
export type Bookings_Inc_Input = {
  client_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bookings" */
export type Bookings_Insert_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  client?: InputMaybe<User_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  practitioner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Bookings_Max_Fields = {
  __typename?: 'bookings_max_fields';
  client_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "bookings" */
export type Bookings_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bookings_Min_Fields = {
  __typename?: 'bookings_min_fields';
  client_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "bookings" */
export type Bookings_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bookings" */
export type Bookings_Mutation_Response = {
  __typename?: 'bookings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookings>;
};

/** on_conflict condition type for table "bookings" */
export type Bookings_On_Conflict = {
  constraint: Bookings_Constraint;
  update_columns?: Array<Bookings_Update_Column>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};

/** Ordering options when selecting data from "bookings". */
export type Bookings_Order_By = {
  answers?: InputMaybe<Order_By>;
  client?: InputMaybe<User_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner?: InputMaybe<User_Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bookings */
export type Bookings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Prepend_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "bookings" */
export enum Bookings_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bookings" */
export type Bookings_Set_Input = {
  answers?: InputMaybe<Scalars['jsonb']>;
  client_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Bookings_Stddev_Fields = {
  __typename?: 'bookings_stddev_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bookings" */
export type Bookings_Stddev_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bookings_Stddev_Pop_Fields = {
  __typename?: 'bookings_stddev_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bookings" */
export type Bookings_Stddev_Pop_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bookings_Stddev_Samp_Fields = {
  __typename?: 'bookings_stddev_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bookings" */
export type Bookings_Stddev_Samp_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bookings_Sum_Fields = {
  __typename?: 'bookings_sum_fields';
  client_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bookings" */
export type Bookings_Sum_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** update columns of table "bookings" */
export enum Bookings_Update_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Bookings_Var_Pop_Fields = {
  __typename?: 'bookings_var_pop_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bookings" */
export type Bookings_Var_Pop_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bookings_Var_Samp_Fields = {
  __typename?: 'bookings_var_samp_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bookings" */
export type Bookings_Var_Samp_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bookings_Variance_Fields = {
  __typename?: 'bookings_variance_fields';
  client_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bookings" */
export type Bookings_Variance_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "client" */
export type Client = {
  __typename?: 'client';
  about?: Maybe<Scalars['String']>;
  appointment_type_preference?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  method_of_contact: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "client" */
export type ClientAppointment_Type_PreferenceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "client" */
export type Client_Aggregate = {
  __typename?: 'client_aggregate';
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  __typename?: 'client_aggregate_fields';
  avg?: Maybe<Client_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
  stddev?: Maybe<Client_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Sum_Fields>;
  var_pop?: Maybe<Client_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Var_Samp_Fields>;
  variance?: Maybe<Client_Variance_Fields>;
};


/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Client_Append_Input = {
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Client_Avg_Fields = {
  __typename?: 'client_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  appointment_type_preference?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  method_of_contact?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint */
  Idx_315a6d0ee0242c59b3189526f3 = 'IDX_315a6d0ee0242c59b3189526f3',
  /** unique or primary key constraint */
  Pk_96da49381769303a6515a8785c7 = 'PK_96da49381769303a6515a8785c7'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Client_Delete_At_Path_Input = {
  appointment_type_preference?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Client_Delete_Elem_Input = {
  appointment_type_preference?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Client_Delete_Key_Input = {
  appointment_type_preference?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "client" */
export type Client_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  method_of_contact?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: 'client_max_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  method_of_contact?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: 'client_min_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  method_of_contact?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: 'client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** on_conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  about?: InputMaybe<Order_By>;
  appointment_type_preference?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method_of_contact?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Client_Prepend_Input = {
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  AppointmentTypePreference = 'appointment_type_preference',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MethodOfContact = 'method_of_contact',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  method_of_contact?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Client_Stddev_Fields = {
  __typename?: 'client_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Client_Stddev_Pop_Fields = {
  __typename?: 'client_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Client_Stddev_Samp_Fields = {
  __typename?: 'client_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Client_Sum_Fields = {
  __typename?: 'client_sum_fields';
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  AppointmentTypePreference = 'appointment_type_preference',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MethodOfContact = 'method_of_contact',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Client_Var_Pop_Fields = {
  __typename?: 'client_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Client_Var_Samp_Fields = {
  __typename?: 'client_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Client_Variance_Fields = {
  __typename?: 'client_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "enrollment" */
export type Enrollment = {
  __typename?: 'enrollment';
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  heard_about_us_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name: Scalars['String'];
  order_id: Scalars['String'];
  primary_phone_number?: Maybe<Scalars['String']>;
  state_or_province: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip?: Maybe<Scalars['String']>;
};

/** aggregated selection of "enrollment" */
export type Enrollment_Aggregate = {
  __typename?: 'enrollment_aggregate';
  aggregate?: Maybe<Enrollment_Aggregate_Fields>;
  nodes: Array<Enrollment>;
};

/** aggregate fields of "enrollment" */
export type Enrollment_Aggregate_Fields = {
  __typename?: 'enrollment_aggregate_fields';
  avg?: Maybe<Enrollment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Enrollment_Max_Fields>;
  min?: Maybe<Enrollment_Min_Fields>;
  stddev?: Maybe<Enrollment_Stddev_Fields>;
  stddev_pop?: Maybe<Enrollment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Enrollment_Stddev_Samp_Fields>;
  sum?: Maybe<Enrollment_Sum_Fields>;
  var_pop?: Maybe<Enrollment_Var_Pop_Fields>;
  var_samp?: Maybe<Enrollment_Var_Samp_Fields>;
  variance?: Maybe<Enrollment_Variance_Fields>;
};


/** aggregate fields of "enrollment" */
export type Enrollment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enrollment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Enrollment_Avg_Fields = {
  __typename?: 'enrollment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "enrollment". All fields are combined with a logical 'AND'. */
export type Enrollment_Bool_Exp = {
  _and?: InputMaybe<Array<Enrollment_Bool_Exp>>;
  _not?: InputMaybe<Enrollment_Bool_Exp>;
  _or?: InputMaybe<Array<Enrollment_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  heard_about_us_at?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  primary_phone_number?: InputMaybe<String_Comparison_Exp>;
  state_or_province?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enrollment" */
export enum Enrollment_Constraint {
  /** unique or primary key constraint */
  Pk_7e200c699fa93865cdcdd025885 = 'PK_7e200c699fa93865cdcdd025885'
}

/** input type for incrementing numeric columns in table "enrollment" */
export type Enrollment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "enrollment" */
export type Enrollment_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  heard_about_us_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  primary_phone_number?: InputMaybe<Scalars['String']>;
  state_or_province?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enrollment_Max_Fields = {
  __typename?: 'enrollment_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  heard_about_us_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  primary_phone_number?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enrollment_Min_Fields = {
  __typename?: 'enrollment_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  heard_about_us_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  primary_phone_number?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enrollment" */
export type Enrollment_Mutation_Response = {
  __typename?: 'enrollment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enrollment>;
};

/** on_conflict condition type for table "enrollment" */
export type Enrollment_On_Conflict = {
  constraint: Enrollment_Constraint;
  update_columns?: Array<Enrollment_Update_Column>;
  where?: InputMaybe<Enrollment_Bool_Exp>;
};

/** Ordering options when selecting data from "enrollment". */
export type Enrollment_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  heard_about_us_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  primary_phone_number?: InputMaybe<Order_By>;
  state_or_province?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enrollment */
export type Enrollment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "enrollment" */
export enum Enrollment_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HeardAboutUsAt = 'heard_about_us_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrimaryPhoneNumber = 'primary_phone_number',
  /** column name */
  StateOrProvince = 'state_or_province',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "enrollment" */
export type Enrollment_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  heard_about_us_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  primary_phone_number?: InputMaybe<Scalars['String']>;
  state_or_province?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Enrollment_Stddev_Fields = {
  __typename?: 'enrollment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Enrollment_Stddev_Pop_Fields = {
  __typename?: 'enrollment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Enrollment_Stddev_Samp_Fields = {
  __typename?: 'enrollment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Enrollment_Sum_Fields = {
  __typename?: 'enrollment_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "enrollment" */
export enum Enrollment_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HeardAboutUsAt = 'heard_about_us_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PrimaryPhoneNumber = 'primary_phone_number',
  /** column name */
  StateOrProvince = 'state_or_province',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type Enrollment_Var_Pop_Fields = {
  __typename?: 'enrollment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Enrollment_Var_Samp_Fields = {
  __typename?: 'enrollment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Enrollment_Variance_Fields = {
  __typename?: 'enrollment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  applyTrainingPaymentCoupon: TrainingOrderCouponUpdate;
  createEnrollment: EnrollmentGql;
  createTrainingPaymentOrder: TrainingOrderDetails;
  deletePublicFileByKey: Scalars['Boolean'];
  /** delete data from the table: "announcement" */
  delete_announcement?: Maybe<Announcement_Mutation_Response>;
  /** delete single row from the table: "announcement" */
  delete_announcement_by_pk?: Maybe<Announcement>;
  /** delete data from the table: "blog" */
  delete_blog?: Maybe<Blog_Mutation_Response>;
  /** delete single row from the table: "blog" */
  delete_blog_by_pk?: Maybe<Blog>;
  /** delete data from the table: "bookings" */
  delete_bookings?: Maybe<Bookings_Mutation_Response>;
  /** delete single row from the table: "bookings" */
  delete_bookings_by_pk?: Maybe<Bookings>;
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>;
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>;
  /** delete data from the table: "enrollment" */
  delete_enrollment?: Maybe<Enrollment_Mutation_Response>;
  /** delete single row from the table: "enrollment" */
  delete_enrollment_by_pk?: Maybe<Enrollment>;
  /** delete data from the table: "practitioner" */
  delete_practitioner?: Maybe<Practitioner_Mutation_Response>;
  /** delete single row from the table: "practitioner" */
  delete_practitioner_by_pk?: Maybe<Practitioner>;
  /** delete data from the table: "practitioner_to_skill" */
  delete_practitioner_to_skill?: Maybe<Practitioner_To_Skill_Mutation_Response>;
  /** delete single row from the table: "practitioner_to_skill" */
  delete_practitioner_to_skill_by_pk?: Maybe<Practitioner_To_Skill>;
  /** delete data from the table: "press" */
  delete_press?: Maybe<Press_Mutation_Response>;
  /** delete single row from the table: "press" */
  delete_press_by_pk?: Maybe<Press>;
  /** delete data from the table: "profile" */
  delete_profile?: Maybe<Profile_Mutation_Response>;
  /** delete single row from the table: "profile" */
  delete_profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "profile_review" */
  delete_profile_review?: Maybe<Profile_Review_Mutation_Response>;
  /** delete single row from the table: "profile_review" */
  delete_profile_review_by_pk?: Maybe<Profile_Review>;
  /** delete data from the table: "public_file" */
  delete_public_file?: Maybe<Public_File_Mutation_Response>;
  /** delete single row from the table: "public_file" */
  delete_public_file_by_pk?: Maybe<Public_File>;
  /** delete data from the table: "skill" */
  delete_skill?: Maybe<Skill_Mutation_Response>;
  /** delete single row from the table: "skill" */
  delete_skill_by_pk?: Maybe<Skill>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  initiatePasswordReset: Success;
  /** insert data into the table: "announcement" */
  insert_announcement?: Maybe<Announcement_Mutation_Response>;
  /** insert a single row into the table: "announcement" */
  insert_announcement_one?: Maybe<Announcement>;
  /** insert data into the table: "blog" */
  insert_blog?: Maybe<Blog_Mutation_Response>;
  /** insert a single row into the table: "blog" */
  insert_blog_one?: Maybe<Blog>;
  /** insert data into the table: "bookings" */
  insert_bookings?: Maybe<Bookings_Mutation_Response>;
  /** insert a single row into the table: "bookings" */
  insert_bookings_one?: Maybe<Bookings>;
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>;
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>;
  /** insert data into the table: "enrollment" */
  insert_enrollment?: Maybe<Enrollment_Mutation_Response>;
  /** insert a single row into the table: "enrollment" */
  insert_enrollment_one?: Maybe<Enrollment>;
  /** insert data into the table: "practitioner" */
  insert_practitioner?: Maybe<Practitioner_Mutation_Response>;
  /** insert a single row into the table: "practitioner" */
  insert_practitioner_one?: Maybe<Practitioner>;
  /** insert data into the table: "practitioner_to_skill" */
  insert_practitioner_to_skill?: Maybe<Practitioner_To_Skill_Mutation_Response>;
  /** insert a single row into the table: "practitioner_to_skill" */
  insert_practitioner_to_skill_one?: Maybe<Practitioner_To_Skill>;
  /** insert data into the table: "press" */
  insert_press?: Maybe<Press_Mutation_Response>;
  /** insert a single row into the table: "press" */
  insert_press_one?: Maybe<Press>;
  /** insert data into the table: "profile" */
  insert_profile?: Maybe<Profile_Mutation_Response>;
  /** insert a single row into the table: "profile" */
  insert_profile_one?: Maybe<Profile>;
  /** insert data into the table: "profile_review" */
  insert_profile_review?: Maybe<Profile_Review_Mutation_Response>;
  /** insert a single row into the table: "profile_review" */
  insert_profile_review_one?: Maybe<Profile_Review>;
  /** insert data into the table: "public_file" */
  insert_public_file?: Maybe<Public_File_Mutation_Response>;
  /** insert a single row into the table: "public_file" */
  insert_public_file_one?: Maybe<Public_File>;
  /** insert data into the table: "skill" */
  insert_skill?: Maybe<Skill_Mutation_Response>;
  /** insert a single row into the table: "skill" */
  insert_skill_one?: Maybe<Skill>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  logIn: AuthTokens;
  resendUserVerificationEmail: Success;
  resetPassword: Success;
  signUp: Success;
  /** update data of the table: "announcement" */
  update_announcement?: Maybe<Announcement_Mutation_Response>;
  /** update single row of the table: "announcement" */
  update_announcement_by_pk?: Maybe<Announcement>;
  /** update data of the table: "blog" */
  update_blog?: Maybe<Blog_Mutation_Response>;
  /** update single row of the table: "blog" */
  update_blog_by_pk?: Maybe<Blog>;
  /** update data of the table: "bookings" */
  update_bookings?: Maybe<Bookings_Mutation_Response>;
  /** update single row of the table: "bookings" */
  update_bookings_by_pk?: Maybe<Bookings>;
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update data of the table: "enrollment" */
  update_enrollment?: Maybe<Enrollment_Mutation_Response>;
  /** update single row of the table: "enrollment" */
  update_enrollment_by_pk?: Maybe<Enrollment>;
  /** update data of the table: "practitioner" */
  update_practitioner?: Maybe<Practitioner_Mutation_Response>;
  /** update single row of the table: "practitioner" */
  update_practitioner_by_pk?: Maybe<Practitioner>;
  /** update data of the table: "practitioner_to_skill" */
  update_practitioner_to_skill?: Maybe<Practitioner_To_Skill_Mutation_Response>;
  /** update single row of the table: "practitioner_to_skill" */
  update_practitioner_to_skill_by_pk?: Maybe<Practitioner_To_Skill>;
  /** update data of the table: "press" */
  update_press?: Maybe<Press_Mutation_Response>;
  /** update single row of the table: "press" */
  update_press_by_pk?: Maybe<Press>;
  /** update data of the table: "profile" */
  update_profile?: Maybe<Profile_Mutation_Response>;
  /** update single row of the table: "profile" */
  update_profile_by_pk?: Maybe<Profile>;
  /** update data of the table: "profile_review" */
  update_profile_review?: Maybe<Profile_Review_Mutation_Response>;
  /** update single row of the table: "profile_review" */
  update_profile_review_by_pk?: Maybe<Profile_Review>;
  /** update data of the table: "public_file" */
  update_public_file?: Maybe<Public_File_Mutation_Response>;
  /** update single row of the table: "public_file" */
  update_public_file_by_pk?: Maybe<Public_File>;
  /** update data of the table: "skill" */
  update_skill?: Maybe<Skill_Mutation_Response>;
  /** update single row of the table: "skill" */
  update_skill_by_pk?: Maybe<Skill>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  uploadPublicFiles: Array<PublicFile>;
  validateEnrollment: Scalars['Boolean'];
  verifyUser: Success;
};


/** mutation root */
export type Mutation_RootApplyTrainingPaymentCouponArgs = {
  input: ApplyTrainingPaymentCouponInput;
};


/** mutation root */
export type Mutation_RootCreateEnrollmentArgs = {
  createEnrollmentInput: CreateEnrollmentInput;
};


/** mutation root */
export type Mutation_RootDeletePublicFileByKeyArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AnnouncementArgs = {
  where: Announcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Announcement_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BlogArgs = {
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BookingsArgs = {
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EnrollmentArgs = {
  where: Enrollment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enrollment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PractitionerArgs = {
  where: Practitioner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practitioner_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Practitioner_To_SkillArgs = {
  where: Practitioner_To_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practitioner_To_Skill_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PressArgs = {
  where: Press_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Press_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_ReviewArgs = {
  where: Profile_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Review_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Public_FileArgs = {
  where: Public_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_File_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SkillArgs = {
  where: Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Skill_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInitiatePasswordResetArgs = {
  email: Scalars['String'];
  env: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AnnouncementArgs = {
  objects: Array<Announcement_Insert_Input>;
  on_conflict?: InputMaybe<Announcement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Announcement_OneArgs = {
  object: Announcement_Insert_Input;
  on_conflict?: InputMaybe<Announcement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BlogArgs = {
  objects: Array<Blog_Insert_Input>;
  on_conflict?: InputMaybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_OneArgs = {
  object: Blog_Insert_Input;
  on_conflict?: InputMaybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookingsArgs = {
  objects: Array<Bookings_Insert_Input>;
  on_conflict?: InputMaybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookings_OneArgs = {
  object: Bookings_Insert_Input;
  on_conflict?: InputMaybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EnrollmentArgs = {
  objects: Array<Enrollment_Insert_Input>;
  on_conflict?: InputMaybe<Enrollment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enrollment_OneArgs = {
  object: Enrollment_Insert_Input;
  on_conflict?: InputMaybe<Enrollment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PractitionerArgs = {
  objects: Array<Practitioner_Insert_Input>;
  on_conflict?: InputMaybe<Practitioner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practitioner_OneArgs = {
  object: Practitioner_Insert_Input;
  on_conflict?: InputMaybe<Practitioner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practitioner_To_SkillArgs = {
  objects: Array<Practitioner_To_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Practitioner_To_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practitioner_To_Skill_OneArgs = {
  object: Practitioner_To_Skill_Insert_Input;
  on_conflict?: InputMaybe<Practitioner_To_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PressArgs = {
  objects: Array<Press_Insert_Input>;
  on_conflict?: InputMaybe<Press_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Press_OneArgs = {
  object: Press_Insert_Input;
  on_conflict?: InputMaybe<Press_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_ReviewArgs = {
  objects: Array<Profile_Review_Insert_Input>;
  on_conflict?: InputMaybe<Profile_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Review_OneArgs = {
  object: Profile_Review_Insert_Input;
  on_conflict?: InputMaybe<Profile_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_FileArgs = {
  objects: Array<Public_File_Insert_Input>;
  on_conflict?: InputMaybe<Public_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_File_OneArgs = {
  object: Public_File_Insert_Input;
  on_conflict?: InputMaybe<Public_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SkillArgs = {
  objects: Array<Skill_Insert_Input>;
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_OneArgs = {
  object: Skill_Insert_Input;
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLogInArgs = {
  loginInput: LoginInput;
};


/** mutation root */
export type Mutation_RootResendUserVerificationEmailArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootSignUpArgs = {
  signUpInput: SignUpInput;
};


/** mutation root */
export type Mutation_RootUpdate_AnnouncementArgs = {
  _inc?: InputMaybe<Announcement_Inc_Input>;
  _set?: InputMaybe<Announcement_Set_Input>;
  where: Announcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Announcement_By_PkArgs = {
  _inc?: InputMaybe<Announcement_Inc_Input>;
  _set?: InputMaybe<Announcement_Set_Input>;
  pk_columns: Announcement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BlogArgs = {
  _inc?: InputMaybe<Blog_Inc_Input>;
  _set?: InputMaybe<Blog_Set_Input>;
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_By_PkArgs = {
  _inc?: InputMaybe<Blog_Inc_Input>;
  _set?: InputMaybe<Blog_Set_Input>;
  pk_columns: Blog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BookingsArgs = {
  _append?: InputMaybe<Bookings_Append_Input>;
  _delete_at_path?: InputMaybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Bookings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Bookings_Delete_Key_Input>;
  _inc?: InputMaybe<Bookings_Inc_Input>;
  _prepend?: InputMaybe<Bookings_Prepend_Input>;
  _set?: InputMaybe<Bookings_Set_Input>;
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_By_PkArgs = {
  _append?: InputMaybe<Bookings_Append_Input>;
  _delete_at_path?: InputMaybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Bookings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Bookings_Delete_Key_Input>;
  _inc?: InputMaybe<Bookings_Inc_Input>;
  _prepend?: InputMaybe<Bookings_Prepend_Input>;
  _set?: InputMaybe<Bookings_Set_Input>;
  pk_columns: Bookings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _append?: InputMaybe<Client_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Inc_Input>;
  _prepend?: InputMaybe<Client_Prepend_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _append?: InputMaybe<Client_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Inc_Input>;
  _prepend?: InputMaybe<Client_Prepend_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EnrollmentArgs = {
  _inc?: InputMaybe<Enrollment_Inc_Input>;
  _set?: InputMaybe<Enrollment_Set_Input>;
  where: Enrollment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enrollment_By_PkArgs = {
  _inc?: InputMaybe<Enrollment_Inc_Input>;
  _set?: InputMaybe<Enrollment_Set_Input>;
  pk_columns: Enrollment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PractitionerArgs = {
  _append?: InputMaybe<Practitioner_Append_Input>;
  _delete_at_path?: InputMaybe<Practitioner_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Practitioner_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Practitioner_Delete_Key_Input>;
  _inc?: InputMaybe<Practitioner_Inc_Input>;
  _prepend?: InputMaybe<Practitioner_Prepend_Input>;
  _set?: InputMaybe<Practitioner_Set_Input>;
  where: Practitioner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practitioner_By_PkArgs = {
  _append?: InputMaybe<Practitioner_Append_Input>;
  _delete_at_path?: InputMaybe<Practitioner_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Practitioner_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Practitioner_Delete_Key_Input>;
  _inc?: InputMaybe<Practitioner_Inc_Input>;
  _prepend?: InputMaybe<Practitioner_Prepend_Input>;
  _set?: InputMaybe<Practitioner_Set_Input>;
  pk_columns: Practitioner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Practitioner_To_SkillArgs = {
  _inc?: InputMaybe<Practitioner_To_Skill_Inc_Input>;
  _set?: InputMaybe<Practitioner_To_Skill_Set_Input>;
  where: Practitioner_To_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practitioner_To_Skill_By_PkArgs = {
  _inc?: InputMaybe<Practitioner_To_Skill_Inc_Input>;
  _set?: InputMaybe<Practitioner_To_Skill_Set_Input>;
  pk_columns: Practitioner_To_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PressArgs = {
  _inc?: InputMaybe<Press_Inc_Input>;
  _set?: InputMaybe<Press_Set_Input>;
  where: Press_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Press_By_PkArgs = {
  _inc?: InputMaybe<Press_Inc_Input>;
  _set?: InputMaybe<Press_Set_Input>;
  pk_columns: Press_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _inc?: InputMaybe<Profile_Inc_Input>;
  _set?: InputMaybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _inc?: InputMaybe<Profile_Inc_Input>;
  _set?: InputMaybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_ReviewArgs = {
  _inc?: InputMaybe<Profile_Review_Inc_Input>;
  _set?: InputMaybe<Profile_Review_Set_Input>;
  where: Profile_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Review_By_PkArgs = {
  _inc?: InputMaybe<Profile_Review_Inc_Input>;
  _set?: InputMaybe<Profile_Review_Set_Input>;
  pk_columns: Profile_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_FileArgs = {
  _inc?: InputMaybe<Public_File_Inc_Input>;
  _set?: InputMaybe<Public_File_Set_Input>;
  where: Public_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_File_By_PkArgs = {
  _inc?: InputMaybe<Public_File_Inc_Input>;
  _set?: InputMaybe<Public_File_Set_Input>;
  pk_columns: Public_File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SkillArgs = {
  _inc?: InputMaybe<Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Set_Input>;
  where: Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_By_PkArgs = {
  _inc?: InputMaybe<Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Set_Input>;
  pk_columns: Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _inc?: InputMaybe<User_Inc_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _inc?: InputMaybe<User_Inc_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUploadPublicFilesArgs = {
  files: Array<Scalars['Upload']>;
};


/** mutation root */
export type Mutation_RootValidateEnrollmentArgs = {
  validateEnrollmentInput: ValidateEnrollmentInput;
};


/** mutation root */
export type Mutation_RootVerifyUserArgs = {
  verifyUserInput: VerifyUserInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "practitioner" */
export type Practitioner = {
  __typename?: 'practitioner';
  about?: Maybe<Scalars['String']>;
  appointment_type?: Maybe<Scalars['jsonb']>;
  area: Scalars['String'];
  booking_link?: Maybe<Scalars['String']>;
  booking_questions: Scalars['jsonb'];
  city: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  fields_to_approve: Scalars['jsonb'];
  id: Scalars['Int'];
  in_call_rate: Scalars['numeric'];
  is_certified: Scalars['Boolean'];
  is_trained: Scalars['Boolean'];
  location_pins: Scalars['jsonb'];
  out_call_rate: Scalars['numeric'];
  /** An array relationship */
  pictures: Array<Public_File>;
  /** An aggregate relationship */
  pictures_aggregate: Public_File_Aggregate;
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['Int'];
  session_information?: Maybe<Scalars['String']>;
  short_bio?: Maybe<Scalars['String']>;
  /** An array relationship */
  skills: Array<Practitioner_To_Skill>;
  /** An aggregate relationship */
  skills_aggregate: Practitioner_To_Skill_Aggregate;
  slug: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  virtual_rate: Scalars['numeric'];
  zip_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "practitioner" */
export type PractitionerAppointment_TypeArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "practitioner" */
export type PractitionerBooking_QuestionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "practitioner" */
export type PractitionerFields_To_ApproveArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "practitioner" */
export type PractitionerLocation_PinsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "practitioner" */
export type PractitionerPicturesArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


/** columns and relationships of "practitioner" */
export type PractitionerPictures_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


/** columns and relationships of "practitioner" */
export type PractitionerSkillsArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


/** columns and relationships of "practitioner" */
export type PractitionerSkills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};

/** aggregated selection of "practitioner" */
export type Practitioner_Aggregate = {
  __typename?: 'practitioner_aggregate';
  aggregate?: Maybe<Practitioner_Aggregate_Fields>;
  nodes: Array<Practitioner>;
};

/** aggregate fields of "practitioner" */
export type Practitioner_Aggregate_Fields = {
  __typename?: 'practitioner_aggregate_fields';
  avg?: Maybe<Practitioner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Practitioner_Max_Fields>;
  min?: Maybe<Practitioner_Min_Fields>;
  stddev?: Maybe<Practitioner_Stddev_Fields>;
  stddev_pop?: Maybe<Practitioner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practitioner_Stddev_Samp_Fields>;
  sum?: Maybe<Practitioner_Sum_Fields>;
  var_pop?: Maybe<Practitioner_Var_Pop_Fields>;
  var_samp?: Maybe<Practitioner_Var_Samp_Fields>;
  variance?: Maybe<Practitioner_Variance_Fields>;
};


/** aggregate fields of "practitioner" */
export type Practitioner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Practitioner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Practitioner_Append_Input = {
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  booking_questions?: InputMaybe<Scalars['jsonb']>;
  fields_to_approve?: InputMaybe<Scalars['jsonb']>;
  location_pins?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Practitioner_Avg_Fields = {
  __typename?: 'practitioner_avg_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "practitioner". All fields are combined with a logical 'AND'. */
export type Practitioner_Bool_Exp = {
  _and?: InputMaybe<Array<Practitioner_Bool_Exp>>;
  _not?: InputMaybe<Practitioner_Bool_Exp>;
  _or?: InputMaybe<Array<Practitioner_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  appointment_type?: InputMaybe<Jsonb_Comparison_Exp>;
  area?: InputMaybe<String_Comparison_Exp>;
  booking_link?: InputMaybe<String_Comparison_Exp>;
  booking_questions?: InputMaybe<Jsonb_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fields_to_approve?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  in_call_rate?: InputMaybe<Numeric_Comparison_Exp>;
  is_certified?: InputMaybe<Boolean_Comparison_Exp>;
  is_trained?: InputMaybe<Boolean_Comparison_Exp>;
  location_pins?: InputMaybe<Jsonb_Comparison_Exp>;
  out_call_rate?: InputMaybe<Numeric_Comparison_Exp>;
  pictures?: InputMaybe<Public_File_Bool_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  session_information?: InputMaybe<String_Comparison_Exp>;
  short_bio?: InputMaybe<String_Comparison_Exp>;
  skills?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  virtual_rate?: InputMaybe<Numeric_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "practitioner" */
export enum Practitioner_Constraint {
  /** unique or primary key constraint */
  Idx_0b968e55cfa81e27d5689a3f80 = 'IDX_0b968e55cfa81e27d5689a3f80',
  /** unique or primary key constraint */
  Idx_752547c919670ee67b784f5465 = 'IDX_752547c919670ee67b784f5465',
  /** unique or primary key constraint */
  Pk_6e23eae1429fc2e897d9eebac42 = 'PK_6e23eae1429fc2e897d9eebac42'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Practitioner_Delete_At_Path_Input = {
  appointment_type?: InputMaybe<Array<Scalars['String']>>;
  booking_questions?: InputMaybe<Array<Scalars['String']>>;
  fields_to_approve?: InputMaybe<Array<Scalars['String']>>;
  location_pins?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Practitioner_Delete_Elem_Input = {
  appointment_type?: InputMaybe<Scalars['Int']>;
  booking_questions?: InputMaybe<Scalars['Int']>;
  fields_to_approve?: InputMaybe<Scalars['Int']>;
  location_pins?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Practitioner_Delete_Key_Input = {
  appointment_type?: InputMaybe<Scalars['String']>;
  booking_questions?: InputMaybe<Scalars['String']>;
  fields_to_approve?: InputMaybe<Scalars['String']>;
  location_pins?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "practitioner" */
export type Practitioner_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  in_call_rate?: InputMaybe<Scalars['numeric']>;
  out_call_rate?: InputMaybe<Scalars['numeric']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  virtual_rate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "practitioner" */
export type Practitioner_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  area?: InputMaybe<Scalars['String']>;
  booking_link?: InputMaybe<Scalars['String']>;
  booking_questions?: InputMaybe<Scalars['jsonb']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  fields_to_approve?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  in_call_rate?: InputMaybe<Scalars['numeric']>;
  is_certified?: InputMaybe<Scalars['Boolean']>;
  is_trained?: InputMaybe<Scalars['Boolean']>;
  location_pins?: InputMaybe<Scalars['jsonb']>;
  out_call_rate?: InputMaybe<Scalars['numeric']>;
  pictures?: InputMaybe<Public_File_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['Int']>;
  session_information?: InputMaybe<Scalars['String']>;
  short_bio?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Practitioner_To_Skill_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  virtual_rate?: InputMaybe<Scalars['numeric']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Practitioner_Max_Fields = {
  __typename?: 'practitioner_max_fields';
  about?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  booking_link?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  in_call_rate?: Maybe<Scalars['numeric']>;
  out_call_rate?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['Int']>;
  session_information?: Maybe<Scalars['String']>;
  short_bio?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  virtual_rate?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Practitioner_Min_Fields = {
  __typename?: 'practitioner_min_fields';
  about?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  booking_link?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  in_call_rate?: Maybe<Scalars['numeric']>;
  out_call_rate?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['Int']>;
  session_information?: Maybe<Scalars['String']>;
  short_bio?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  virtual_rate?: Maybe<Scalars['numeric']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "practitioner" */
export type Practitioner_Mutation_Response = {
  __typename?: 'practitioner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practitioner>;
};

/** input type for inserting object relation for remote table "practitioner" */
export type Practitioner_Obj_Rel_Insert_Input = {
  data: Practitioner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Practitioner_On_Conflict>;
};

/** on_conflict condition type for table "practitioner" */
export type Practitioner_On_Conflict = {
  constraint: Practitioner_Constraint;
  update_columns?: Array<Practitioner_Update_Column>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
};

/** Ordering options when selecting data from "practitioner". */
export type Practitioner_Order_By = {
  about?: InputMaybe<Order_By>;
  appointment_type?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  booking_link?: InputMaybe<Order_By>;
  booking_questions?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  fields_to_approve?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_call_rate?: InputMaybe<Order_By>;
  is_certified?: InputMaybe<Order_By>;
  is_trained?: InputMaybe<Order_By>;
  location_pins?: InputMaybe<Order_By>;
  out_call_rate?: InputMaybe<Order_By>;
  pictures_aggregate?: InputMaybe<Public_File_Aggregate_Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  session_information?: InputMaybe<Order_By>;
  short_bio?: InputMaybe<Order_By>;
  skills_aggregate?: InputMaybe<Practitioner_To_Skill_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  virtual_rate?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: practitioner */
export type Practitioner_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Practitioner_Prepend_Input = {
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  booking_questions?: InputMaybe<Scalars['jsonb']>;
  fields_to_approve?: InputMaybe<Scalars['jsonb']>;
  location_pins?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "practitioner" */
export enum Practitioner_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  AppointmentType = 'appointment_type',
  /** column name */
  Area = 'area',
  /** column name */
  BookingLink = 'booking_link',
  /** column name */
  BookingQuestions = 'booking_questions',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FieldsToApprove = 'fields_to_approve',
  /** column name */
  Id = 'id',
  /** column name */
  InCallRate = 'in_call_rate',
  /** column name */
  IsCertified = 'is_certified',
  /** column name */
  IsTrained = 'is_trained',
  /** column name */
  LocationPins = 'location_pins',
  /** column name */
  OutCallRate = 'out_call_rate',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SessionInformation = 'session_information',
  /** column name */
  ShortBio = 'short_bio',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VirtualRate = 'virtual_rate',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "practitioner" */
export type Practitioner_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  area?: InputMaybe<Scalars['String']>;
  booking_link?: InputMaybe<Scalars['String']>;
  booking_questions?: InputMaybe<Scalars['jsonb']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  fields_to_approve?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  in_call_rate?: InputMaybe<Scalars['numeric']>;
  is_certified?: InputMaybe<Scalars['Boolean']>;
  is_trained?: InputMaybe<Scalars['Boolean']>;
  location_pins?: InputMaybe<Scalars['jsonb']>;
  out_call_rate?: InputMaybe<Scalars['numeric']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  session_information?: InputMaybe<Scalars['String']>;
  short_bio?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  virtual_rate?: InputMaybe<Scalars['numeric']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Practitioner_Stddev_Fields = {
  __typename?: 'practitioner_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Practitioner_Stddev_Pop_Fields = {
  __typename?: 'practitioner_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Practitioner_Stddev_Samp_Fields = {
  __typename?: 'practitioner_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Practitioner_Sum_Fields = {
  __typename?: 'practitioner_sum_fields';
  id?: Maybe<Scalars['Int']>;
  in_call_rate?: Maybe<Scalars['numeric']>;
  out_call_rate?: Maybe<Scalars['numeric']>;
  profile_id?: Maybe<Scalars['Int']>;
  virtual_rate?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "practitioner_to_skill" */
export type Practitioner_To_Skill = {
  __typename?: 'practitioner_to_skill';
  id: Scalars['Int'];
  /** An object relationship */
  practitioner: Practitioner;
  practitioner_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "practitioner_to_skill" */
export type Practitioner_To_Skill_Aggregate = {
  __typename?: 'practitioner_to_skill_aggregate';
  aggregate?: Maybe<Practitioner_To_Skill_Aggregate_Fields>;
  nodes: Array<Practitioner_To_Skill>;
};

/** aggregate fields of "practitioner_to_skill" */
export type Practitioner_To_Skill_Aggregate_Fields = {
  __typename?: 'practitioner_to_skill_aggregate_fields';
  avg?: Maybe<Practitioner_To_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Practitioner_To_Skill_Max_Fields>;
  min?: Maybe<Practitioner_To_Skill_Min_Fields>;
  stddev?: Maybe<Practitioner_To_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Practitioner_To_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practitioner_To_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Practitioner_To_Skill_Sum_Fields>;
  var_pop?: Maybe<Practitioner_To_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Practitioner_To_Skill_Var_Samp_Fields>;
  variance?: Maybe<Practitioner_To_Skill_Variance_Fields>;
};


/** aggregate fields of "practitioner_to_skill" */
export type Practitioner_To_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Practitioner_To_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Practitioner_To_Skill_Max_Order_By>;
  min?: InputMaybe<Practitioner_To_Skill_Min_Order_By>;
  stddev?: InputMaybe<Practitioner_To_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Practitioner_To_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Practitioner_To_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Practitioner_To_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Practitioner_To_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Practitioner_To_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Practitioner_To_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practitioner_to_skill" */
export type Practitioner_To_Skill_Arr_Rel_Insert_Input = {
  data: Array<Practitioner_To_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Practitioner_To_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Practitioner_To_Skill_Avg_Fields = {
  __typename?: 'practitioner_to_skill_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "practitioner_to_skill". All fields are combined with a logical 'AND'. */
export type Practitioner_To_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Practitioner_To_Skill_Bool_Exp>>;
  _not?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Practitioner_To_Skill_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  practitioner?: InputMaybe<Practitioner_Bool_Exp>;
  practitioner_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "practitioner_to_skill" */
export enum Practitioner_To_Skill_Constraint {
  /** unique or primary key constraint */
  Pk_0587b836d60ada58f80e618ffe2 = 'PK_0587b836d60ada58f80e618ffe2',
  /** unique or primary key constraint */
  UqDcea671c6a78537bfcc7bd4e5ba = 'UQ_dcea671c6a78537bfcc7bd4e5ba'
}

/** input type for incrementing numeric columns in table "practitioner_to_skill" */
export type Practitioner_To_Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "practitioner_to_skill" */
export type Practitioner_To_Skill_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  practitioner?: InputMaybe<Practitioner_Obj_Rel_Insert_Input>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Practitioner_To_Skill_Max_Fields = {
  __typename?: 'practitioner_to_skill_max_fields';
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  skill_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Practitioner_To_Skill_Min_Fields = {
  __typename?: 'practitioner_to_skill_min_fields';
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  skill_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "practitioner_to_skill" */
export type Practitioner_To_Skill_Mutation_Response = {
  __typename?: 'practitioner_to_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practitioner_To_Skill>;
};

/** on_conflict condition type for table "practitioner_to_skill" */
export type Practitioner_To_Skill_On_Conflict = {
  constraint: Practitioner_To_Skill_Constraint;
  update_columns?: Array<Practitioner_To_Skill_Update_Column>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "practitioner_to_skill". */
export type Practitioner_To_Skill_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner?: InputMaybe<Practitioner_Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: practitioner_to_skill */
export type Practitioner_To_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "practitioner_to_skill" */
export enum Practitioner_To_Skill_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  SkillId = 'skill_id'
}

/** input type for updating data in table "practitioner_to_skill" */
export type Practitioner_To_Skill_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Practitioner_To_Skill_Stddev_Fields = {
  __typename?: 'practitioner_to_skill_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Practitioner_To_Skill_Stddev_Pop_Fields = {
  __typename?: 'practitioner_to_skill_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Practitioner_To_Skill_Stddev_Samp_Fields = {
  __typename?: 'practitioner_to_skill_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Practitioner_To_Skill_Sum_Fields = {
  __typename?: 'practitioner_to_skill_sum_fields';
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  skill_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "practitioner_to_skill" */
export enum Practitioner_To_Skill_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  SkillId = 'skill_id'
}

/** aggregate var_pop on columns */
export type Practitioner_To_Skill_Var_Pop_Fields = {
  __typename?: 'practitioner_to_skill_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Practitioner_To_Skill_Var_Samp_Fields = {
  __typename?: 'practitioner_to_skill_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Practitioner_To_Skill_Variance_Fields = {
  __typename?: 'practitioner_to_skill_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
  skill_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "practitioner_to_skill" */
export type Practitioner_To_Skill_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "practitioner" */
export enum Practitioner_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  AppointmentType = 'appointment_type',
  /** column name */
  Area = 'area',
  /** column name */
  BookingLink = 'booking_link',
  /** column name */
  BookingQuestions = 'booking_questions',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FieldsToApprove = 'fields_to_approve',
  /** column name */
  Id = 'id',
  /** column name */
  InCallRate = 'in_call_rate',
  /** column name */
  IsCertified = 'is_certified',
  /** column name */
  IsTrained = 'is_trained',
  /** column name */
  LocationPins = 'location_pins',
  /** column name */
  OutCallRate = 'out_call_rate',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SessionInformation = 'session_information',
  /** column name */
  ShortBio = 'short_bio',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VirtualRate = 'virtual_rate',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Practitioner_Var_Pop_Fields = {
  __typename?: 'practitioner_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Practitioner_Var_Samp_Fields = {
  __typename?: 'practitioner_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Practitioner_Variance_Fields = {
  __typename?: 'practitioner_variance_fields';
  id?: Maybe<Scalars['Float']>;
  in_call_rate?: Maybe<Scalars['Float']>;
  out_call_rate?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  virtual_rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "press" */
export type Press = {
  __typename?: 'press';
  audio?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  featured_image?: Maybe<Public_File>;
  featured_image_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  show_more_text?: Maybe<Scalars['String']>;
  show_more_url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video?: Maybe<Scalars['String']>;
};

/** aggregated selection of "press" */
export type Press_Aggregate = {
  __typename?: 'press_aggregate';
  aggregate?: Maybe<Press_Aggregate_Fields>;
  nodes: Array<Press>;
};

/** aggregate fields of "press" */
export type Press_Aggregate_Fields = {
  __typename?: 'press_aggregate_fields';
  avg?: Maybe<Press_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Press_Max_Fields>;
  min?: Maybe<Press_Min_Fields>;
  stddev?: Maybe<Press_Stddev_Fields>;
  stddev_pop?: Maybe<Press_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Press_Stddev_Samp_Fields>;
  sum?: Maybe<Press_Sum_Fields>;
  var_pop?: Maybe<Press_Var_Pop_Fields>;
  var_samp?: Maybe<Press_Var_Samp_Fields>;
  variance?: Maybe<Press_Variance_Fields>;
};


/** aggregate fields of "press" */
export type Press_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Press_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Press_Avg_Fields = {
  __typename?: 'press_avg_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "press". All fields are combined with a logical 'AND'. */
export type Press_Bool_Exp = {
  _and?: InputMaybe<Array<Press_Bool_Exp>>;
  _not?: InputMaybe<Press_Bool_Exp>;
  _or?: InputMaybe<Array<Press_Bool_Exp>>;
  audio?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  featured_image?: InputMaybe<Public_File_Bool_Exp>;
  featured_image_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  show_more_text?: InputMaybe<String_Comparison_Exp>;
  show_more_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "press" */
export enum Press_Constraint {
  /** unique or primary key constraint */
  IdxB6b87553a5c14f8af568512261 = 'IDX_b6b87553a5c14f8af568512261',
  /** unique or primary key constraint */
  Pk_95c827fbded862104c6c6b48588 = 'PK_95c827fbded862104c6c6b48588'
}

/** input type for incrementing numeric columns in table "press" */
export type Press_Inc_Input = {
  featured_image_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "press" */
export type Press_Insert_Input = {
  audio?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  featured_image?: InputMaybe<Public_File_Obj_Rel_Insert_Input>;
  featured_image_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  show_more_text?: InputMaybe<Scalars['String']>;
  show_more_url?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Press_Max_Fields = {
  __typename?: 'press_max_fields';
  audio?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  featured_image_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  show_more_text?: Maybe<Scalars['String']>;
  show_more_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Press_Min_Fields = {
  __typename?: 'press_min_fields';
  audio?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  featured_image_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  show_more_text?: Maybe<Scalars['String']>;
  show_more_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "press" */
export type Press_Mutation_Response = {
  __typename?: 'press_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Press>;
};

/** on_conflict condition type for table "press" */
export type Press_On_Conflict = {
  constraint: Press_Constraint;
  update_columns?: Array<Press_Update_Column>;
  where?: InputMaybe<Press_Bool_Exp>;
};

/** Ordering options when selecting data from "press". */
export type Press_Order_By = {
  audio?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  featured_image?: InputMaybe<Public_File_Order_By>;
  featured_image_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  show_more_text?: InputMaybe<Order_By>;
  show_more_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
};

/** primary key columns input for table: press */
export type Press_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "press" */
export enum Press_Select_Column {
  /** column name */
  Audio = 'audio',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeaturedImageId = 'featured_image_id',
  /** column name */
  Id = 'id',
  /** column name */
  ShowMoreText = 'show_more_text',
  /** column name */
  ShowMoreUrl = 'show_more_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Video = 'video'
}

/** input type for updating data in table "press" */
export type Press_Set_Input = {
  audio?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  featured_image_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  show_more_text?: InputMaybe<Scalars['String']>;
  show_more_url?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Press_Stddev_Fields = {
  __typename?: 'press_stddev_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Press_Stddev_Pop_Fields = {
  __typename?: 'press_stddev_pop_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Press_Stddev_Samp_Fields = {
  __typename?: 'press_stddev_samp_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Press_Sum_Fields = {
  __typename?: 'press_sum_fields';
  featured_image_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "press" */
export enum Press_Update_Column {
  /** column name */
  Audio = 'audio',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeaturedImageId = 'featured_image_id',
  /** column name */
  Id = 'id',
  /** column name */
  ShowMoreText = 'show_more_text',
  /** column name */
  ShowMoreUrl = 'show_more_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Video = 'video'
}

/** aggregate var_pop on columns */
export type Press_Var_Pop_Fields = {
  __typename?: 'press_var_pop_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Press_Var_Samp_Fields = {
  __typename?: 'press_var_samp_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Press_Variance_Fields = {
  __typename?: 'press_variance_fields';
  featured_image_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An object relationship */
  client?: Maybe<Client>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner: User;
  owner_id: Scalars['Int'];
  /** An object relationship */
  practitioner?: Maybe<Practitioner>;
  /** An object relationship */
  profile_picture?: Maybe<Public_File>;
  profile_picture_id?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  avg?: Maybe<Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
  stddev?: Maybe<Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Sum_Fields>;
  var_pop?: Maybe<Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Var_Samp_Fields>;
  variance?: Maybe<Profile_Variance_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  avg?: InputMaybe<Profile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profile_Max_Order_By>;
  min?: InputMaybe<Profile_Min_Order_By>;
  stddev?: InputMaybe<Profile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Profile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Profile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Profile_Sum_Order_By>;
  var_pop?: InputMaybe<Profile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Profile_Var_Samp_Order_By>;
  variance?: InputMaybe<Profile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Avg_Fields = {
  __typename?: 'profile_avg_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile" */
export type Profile_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Bool_Exp>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  owner_id?: InputMaybe<Int_Comparison_Exp>;
  practitioner?: InputMaybe<Practitioner_Bool_Exp>;
  profile_picture?: InputMaybe<Public_File_Bool_Exp>;
  profile_picture_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint */
  Idx_2a1abfd1f4968f422d93b8639b = 'IDX_2a1abfd1f4968f422d93b8639b',
  /** unique or primary key constraint */
  Pk_3dd8bfc97e4a77c70971591bdcb = 'PK_3dd8bfc97e4a77c70971591bdcb'
}

/** input type for incrementing numeric columns in table "profile" */
export type Profile_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  profile_picture_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile" */
export type Profile_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['Int']>;
  practitioner?: InputMaybe<Practitioner_Obj_Rel_Insert_Input>;
  profile_picture?: InputMaybe<Public_File_Obj_Rel_Insert_Input>;
  profile_picture_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  profile_picture_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['Int']>;
  profile_picture_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profile" */
export type Profile_Mutation_Response = {
  __typename?: 'profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profile_On_Conflict>;
};

/** on_conflict condition type for table "profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "profile". */
export type Profile_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  owner?: InputMaybe<User_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  practitioner?: InputMaybe<Practitioner_Order_By>;
  profile_picture?: InputMaybe<Public_File_Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile */
export type Profile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "profile_review" */
export type Profile_Review = {
  __typename?: 'profile_review';
  comment: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  profile_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  reviewer?: Maybe<User>;
  reviewer_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_review" */
export type Profile_Review_Aggregate = {
  __typename?: 'profile_review_aggregate';
  aggregate?: Maybe<Profile_Review_Aggregate_Fields>;
  nodes: Array<Profile_Review>;
};

/** aggregate fields of "profile_review" */
export type Profile_Review_Aggregate_Fields = {
  __typename?: 'profile_review_aggregate_fields';
  avg?: Maybe<Profile_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Review_Max_Fields>;
  min?: Maybe<Profile_Review_Min_Fields>;
  stddev?: Maybe<Profile_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Review_Sum_Fields>;
  var_pop?: Maybe<Profile_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Review_Var_Samp_Fields>;
  variance?: Maybe<Profile_Review_Variance_Fields>;
};


/** aggregate fields of "profile_review" */
export type Profile_Review_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Review_Avg_Fields = {
  __typename?: 'profile_review_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "profile_review". All fields are combined with a logical 'AND'. */
export type Profile_Review_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Review_Bool_Exp>>;
  _not?: InputMaybe<Profile_Review_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Review_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer?: InputMaybe<User_Bool_Exp>;
  reviewer_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_review" */
export enum Profile_Review_Constraint {
  /** unique or primary key constraint */
  Pk_15d1e7993141458f6804a7bdc07 = 'PK_15d1e7993141458f6804a7bdc07'
}

/** input type for incrementing numeric columns in table "profile_review" */
export type Profile_Review_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "profile_review" */
export type Profile_Review_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['Int']>;
  reviewer?: InputMaybe<User_Obj_Rel_Insert_Input>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Review_Max_Fields = {
  __typename?: 'profile_review_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Review_Min_Fields = {
  __typename?: 'profile_review_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "profile_review" */
export type Profile_Review_Mutation_Response = {
  __typename?: 'profile_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Review>;
};

/** on_conflict condition type for table "profile_review" */
export type Profile_Review_On_Conflict = {
  constraint: Profile_Review_Constraint;
  update_columns?: Array<Profile_Review_Update_Column>;
  where?: InputMaybe<Profile_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_review". */
export type Profile_Review_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  reviewer?: InputMaybe<User_Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile_review */
export type Profile_Review_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "profile_review" */
export enum Profile_Review_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_review" */
export type Profile_Review_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Review_Stddev_Fields = {
  __typename?: 'profile_review_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Review_Stddev_Pop_Fields = {
  __typename?: 'profile_review_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Review_Stddev_Samp_Fields = {
  __typename?: 'profile_review_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Review_Sum_Fields = {
  __typename?: 'profile_review_sum_fields';
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  reviewer_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "profile_review" */
export enum Profile_Review_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Review_Var_Pop_Fields = {
  __typename?: 'profile_review_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Review_Var_Samp_Fields = {
  __typename?: 'profile_review_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Review_Variance_Fields = {
  __typename?: 'profile_review_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  reviewer_id?: Maybe<Scalars['Float']>;
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile" */
export type Profile_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['Int']>;
  profile_picture_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Stddev_Fields = {
  __typename?: 'profile_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile" */
export type Profile_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Stddev_Pop_Fields = {
  __typename?: 'profile_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile" */
export type Profile_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Stddev_Samp_Fields = {
  __typename?: 'profile_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile" */
export type Profile_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Sum_Fields = {
  __typename?: 'profile_sum_fields';
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['Int']>;
  profile_picture_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile" */
export type Profile_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** update columns of table "profile" */
export enum Profile_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Var_Pop_Fields = {
  __typename?: 'profile_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile" */
export type Profile_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Var_Samp_Fields = {
  __typename?: 'profile_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile" */
export type Profile_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Variance_Fields = {
  __typename?: 'profile_variance_fields';
  id?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['Float']>;
  profile_picture_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile" */
export type Profile_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "public_file" */
export type Public_File = {
  __typename?: 'public_file';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  /** An object relationship */
  practitioner?: Maybe<Practitioner>;
  practitioner_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  profiles: Array<Profile>;
  /** An aggregate relationship */
  profiles_aggregate: Profile_Aggregate;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "public_file" */
export type Public_FileProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** columns and relationships of "public_file" */
export type Public_FileProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};

/** aggregated selection of "public_file" */
export type Public_File_Aggregate = {
  __typename?: 'public_file_aggregate';
  aggregate?: Maybe<Public_File_Aggregate_Fields>;
  nodes: Array<Public_File>;
};

/** aggregate fields of "public_file" */
export type Public_File_Aggregate_Fields = {
  __typename?: 'public_file_aggregate_fields';
  avg?: Maybe<Public_File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Public_File_Max_Fields>;
  min?: Maybe<Public_File_Min_Fields>;
  stddev?: Maybe<Public_File_Stddev_Fields>;
  stddev_pop?: Maybe<Public_File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Public_File_Stddev_Samp_Fields>;
  sum?: Maybe<Public_File_Sum_Fields>;
  var_pop?: Maybe<Public_File_Var_Pop_Fields>;
  var_samp?: Maybe<Public_File_Var_Samp_Fields>;
  variance?: Maybe<Public_File_Variance_Fields>;
};


/** aggregate fields of "public_file" */
export type Public_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Public_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "public_file" */
export type Public_File_Aggregate_Order_By = {
  avg?: InputMaybe<Public_File_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Public_File_Max_Order_By>;
  min?: InputMaybe<Public_File_Min_Order_By>;
  stddev?: InputMaybe<Public_File_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Public_File_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Public_File_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Public_File_Sum_Order_By>;
  var_pop?: InputMaybe<Public_File_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Public_File_Var_Samp_Order_By>;
  variance?: InputMaybe<Public_File_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "public_file" */
export type Public_File_Arr_Rel_Insert_Input = {
  data: Array<Public_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Public_File_On_Conflict>;
};

/** aggregate avg on columns */
export type Public_File_Avg_Fields = {
  __typename?: 'public_file_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "public_file" */
export type Public_File_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_file". All fields are combined with a logical 'AND'. */
export type Public_File_Bool_Exp = {
  _and?: InputMaybe<Array<Public_File_Bool_Exp>>;
  _not?: InputMaybe<Public_File_Bool_Exp>;
  _or?: InputMaybe<Array<Public_File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  practitioner?: InputMaybe<Practitioner_Bool_Exp>;
  practitioner_id?: InputMaybe<Int_Comparison_Exp>;
  profiles?: InputMaybe<Profile_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_file" */
export enum Public_File_Constraint {
  /** unique or primary key constraint */
  Idx_546e2fac213b571ee22865d605 = 'IDX_546e2fac213b571ee22865d605',
  /** unique or primary key constraint */
  PkBf2f5ba5aa6e3453b04cb4e4720 = 'PK_bf2f5ba5aa6e3453b04cb4e4720'
}

/** input type for incrementing numeric columns in table "public_file" */
export type Public_File_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "public_file" */
export type Public_File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Practitioner_Obj_Rel_Insert_Input>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  profiles?: InputMaybe<Profile_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Public_File_Max_Fields = {
  __typename?: 'public_file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "public_file" */
export type Public_File_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Public_File_Min_Fields = {
  __typename?: 'public_file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  practitioner_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "public_file" */
export type Public_File_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "public_file" */
export type Public_File_Mutation_Response = {
  __typename?: 'public_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_File>;
};

/** input type for inserting object relation for remote table "public_file" */
export type Public_File_Obj_Rel_Insert_Input = {
  data: Public_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Public_File_On_Conflict>;
};

/** on_conflict condition type for table "public_file" */
export type Public_File_On_Conflict = {
  constraint: Public_File_Constraint;
  update_columns?: Array<Public_File_Update_Column>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};

/** Ordering options when selecting data from "public_file". */
export type Public_File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  practitioner?: InputMaybe<Practitioner_Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profile_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: public_file */
export type Public_File_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "public_file" */
export enum Public_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "public_file" */
export type Public_File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['String']>;
  practitioner_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Public_File_Stddev_Fields = {
  __typename?: 'public_file_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "public_file" */
export type Public_File_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Public_File_Stddev_Pop_Fields = {
  __typename?: 'public_file_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "public_file" */
export type Public_File_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Public_File_Stddev_Samp_Fields = {
  __typename?: 'public_file_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "public_file" */
export type Public_File_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Public_File_Sum_Fields = {
  __typename?: 'public_file_sum_fields';
  id?: Maybe<Scalars['Int']>;
  practitioner_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "public_file" */
export type Public_File_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** update columns of table "public_file" */
export enum Public_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  PractitionerId = 'practitioner_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Public_File_Var_Pop_Fields = {
  __typename?: 'public_file_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "public_file" */
export type Public_File_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Public_File_Var_Samp_Fields = {
  __typename?: 'public_file_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "public_file" */
export type Public_File_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Public_File_Variance_Fields = {
  __typename?: 'public_file_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practitioner_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "public_file" */
export type Public_File_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practitioner_id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "announcement" */
  announcement: Array<Announcement>;
  /** fetch aggregated fields from the table: "announcement" */
  announcement_aggregate: Announcement_Aggregate;
  /** fetch data from the table: "announcement" using primary key columns */
  announcement_by_pk?: Maybe<Announcement>;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  bookings_by_pk?: Maybe<Bookings>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "enrollment" */
  enrollment: Array<Enrollment>;
  /** fetch aggregated fields from the table: "enrollment" */
  enrollment_aggregate: Enrollment_Aggregate;
  /** fetch data from the table: "enrollment" using primary key columns */
  enrollment_by_pk?: Maybe<Enrollment>;
  me: PartialUser;
  /** fetch data from the table: "practitioner" */
  practitioner: Array<Practitioner>;
  /** fetch aggregated fields from the table: "practitioner" */
  practitioner_aggregate: Practitioner_Aggregate;
  /** fetch data from the table: "practitioner" using primary key columns */
  practitioner_by_pk?: Maybe<Practitioner>;
  /** fetch data from the table: "practitioner_to_skill" */
  practitioner_to_skill: Array<Practitioner_To_Skill>;
  /** fetch aggregated fields from the table: "practitioner_to_skill" */
  practitioner_to_skill_aggregate: Practitioner_To_Skill_Aggregate;
  /** fetch data from the table: "practitioner_to_skill" using primary key columns */
  practitioner_to_skill_by_pk?: Maybe<Practitioner_To_Skill>;
  /** fetch data from the table: "press" */
  press: Array<Press>;
  /** fetch aggregated fields from the table: "press" */
  press_aggregate: Press_Aggregate;
  /** fetch data from the table: "press" using primary key columns */
  press_by_pk?: Maybe<Press>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "profile_review" */
  profile_review: Array<Profile_Review>;
  /** fetch aggregated fields from the table: "profile_review" */
  profile_review_aggregate: Profile_Review_Aggregate;
  /** fetch data from the table: "profile_review" using primary key columns */
  profile_review_by_pk?: Maybe<Profile_Review>;
  /** fetch data from the table: "public_file" */
  public_file: Array<Public_File>;
  /** fetch aggregated fields from the table: "public_file" */
  public_file_aggregate: Public_File_Aggregate;
  /** fetch data from the table: "public_file" using primary key columns */
  public_file_by_pk?: Maybe<Public_File>;
  refreshTokens: AuthTokens;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk?: Maybe<Skill>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Query_RootAnnouncementArgs = {
  distinct_on?: InputMaybe<Array<Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Announcement_Order_By>>;
  where?: InputMaybe<Announcement_Bool_Exp>;
};


export type Query_RootAnnouncement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Announcement_Order_By>>;
  where?: InputMaybe<Announcement_Bool_Exp>;
};


export type Query_RootAnnouncement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBlogArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBookingsArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Query_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Query_RootClient_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEnrollmentArgs = {
  distinct_on?: InputMaybe<Array<Enrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollment_Order_By>>;
  where?: InputMaybe<Enrollment_Bool_Exp>;
};


export type Query_RootEnrollment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollment_Order_By>>;
  where?: InputMaybe<Enrollment_Bool_Exp>;
};


export type Query_RootEnrollment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPractitionerArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_Order_By>>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
};


export type Query_RootPractitioner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_Order_By>>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
};


export type Query_RootPractitioner_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPractitioner_To_SkillArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


export type Query_RootPractitioner_To_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


export type Query_RootPractitioner_To_Skill_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPressArgs = {
  distinct_on?: InputMaybe<Array<Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Press_Order_By>>;
  where?: InputMaybe<Press_Bool_Exp>;
};


export type Query_RootPress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Press_Order_By>>;
  where?: InputMaybe<Press_Bool_Exp>;
};


export type Query_RootPress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProfile_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Profile_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Review_Order_By>>;
  where?: InputMaybe<Profile_Review_Bool_Exp>;
};


export type Query_RootProfile_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Review_Order_By>>;
  where?: InputMaybe<Profile_Review_Bool_Exp>;
};


export type Query_RootProfile_Review_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPublic_FileArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


export type Query_RootPublic_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


export type Query_RootPublic_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRefreshTokensArgs = {
  refreshToken: Scalars['String'];
};


export type Query_RootSkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};


export type Query_RootSkill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};


export type Query_RootSkill_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "skill" */
export type Skill = {
  __typename?: 'skill';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  practitioners: Array<Practitioner_To_Skill>;
  /** An aggregate relationship */
  practitioners_aggregate: Practitioner_To_Skill_Aggregate;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "skill" */
export type SkillPractitionersArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


/** columns and relationships of "skill" */
export type SkillPractitioners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};

/** aggregated selection of "skill" */
export type Skill_Aggregate = {
  __typename?: 'skill_aggregate';
  aggregate?: Maybe<Skill_Aggregate_Fields>;
  nodes: Array<Skill>;
};

/** aggregate fields of "skill" */
export type Skill_Aggregate_Fields = {
  __typename?: 'skill_aggregate_fields';
  avg?: Maybe<Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Max_Fields>;
  min?: Maybe<Skill_Min_Fields>;
  stddev?: Maybe<Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Sum_Fields>;
  var_pop?: Maybe<Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Var_Samp_Fields>;
  variance?: Maybe<Skill_Variance_Fields>;
};


/** aggregate fields of "skill" */
export type Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Avg_Fields = {
  __typename?: 'skill_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill". All fields are combined with a logical 'AND'. */
export type Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Skill_Bool_Exp>>;
  _not?: InputMaybe<Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Skill_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  practitioners?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill" */
export enum Skill_Constraint {
  /** unique or primary key constraint */
  IdxF208c4fe2d044a38c1295d999a = 'IDX_f208c4fe2d044a38c1295d999a',
  /** unique or primary key constraint */
  PkA0d33334424e64fb78dc3ce7196 = 'PK_a0d33334424e64fb78dc3ce7196'
}

/** input type for incrementing numeric columns in table "skill" */
export type Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill" */
export type Skill_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  practitioners?: InputMaybe<Practitioner_To_Skill_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Skill_Max_Fields = {
  __typename?: 'skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Skill_Min_Fields = {
  __typename?: 'skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "skill" */
export type Skill_Mutation_Response = {
  __typename?: 'skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill>;
};

/** input type for inserting object relation for remote table "skill" */
export type Skill_Obj_Rel_Insert_Input = {
  data: Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};

/** on_conflict condition type for table "skill" */
export type Skill_On_Conflict = {
  constraint: Skill_Constraint;
  update_columns?: Array<Skill_Update_Column>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "skill". */
export type Skill_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practitioners_aggregate?: InputMaybe<Practitioner_To_Skill_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skill */
export type Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "skill" */
export enum Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "skill" */
export type Skill_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Skill_Stddev_Fields = {
  __typename?: 'skill_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Stddev_Pop_Fields = {
  __typename?: 'skill_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Stddev_Samp_Fields = {
  __typename?: 'skill_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Sum_Fields = {
  __typename?: 'skill_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "skill" */
export enum Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Skill_Var_Pop_Fields = {
  __typename?: 'skill_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Var_Samp_Fields = {
  __typename?: 'skill_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Variance_Fields = {
  __typename?: 'skill_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "announcement" */
  announcement: Array<Announcement>;
  /** fetch aggregated fields from the table: "announcement" */
  announcement_aggregate: Announcement_Aggregate;
  /** fetch data from the table: "announcement" using primary key columns */
  announcement_by_pk?: Maybe<Announcement>;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  bookings_by_pk?: Maybe<Bookings>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "enrollment" */
  enrollment: Array<Enrollment>;
  /** fetch aggregated fields from the table: "enrollment" */
  enrollment_aggregate: Enrollment_Aggregate;
  /** fetch data from the table: "enrollment" using primary key columns */
  enrollment_by_pk?: Maybe<Enrollment>;
  /** fetch data from the table: "practitioner" */
  practitioner: Array<Practitioner>;
  /** fetch aggregated fields from the table: "practitioner" */
  practitioner_aggregate: Practitioner_Aggregate;
  /** fetch data from the table: "practitioner" using primary key columns */
  practitioner_by_pk?: Maybe<Practitioner>;
  /** fetch data from the table: "practitioner_to_skill" */
  practitioner_to_skill: Array<Practitioner_To_Skill>;
  /** fetch aggregated fields from the table: "practitioner_to_skill" */
  practitioner_to_skill_aggregate: Practitioner_To_Skill_Aggregate;
  /** fetch data from the table: "practitioner_to_skill" using primary key columns */
  practitioner_to_skill_by_pk?: Maybe<Practitioner_To_Skill>;
  /** fetch data from the table: "press" */
  press: Array<Press>;
  /** fetch aggregated fields from the table: "press" */
  press_aggregate: Press_Aggregate;
  /** fetch data from the table: "press" using primary key columns */
  press_by_pk?: Maybe<Press>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "profile_review" */
  profile_review: Array<Profile_Review>;
  /** fetch aggregated fields from the table: "profile_review" */
  profile_review_aggregate: Profile_Review_Aggregate;
  /** fetch data from the table: "profile_review" using primary key columns */
  profile_review_by_pk?: Maybe<Profile_Review>;
  /** fetch data from the table: "public_file" */
  public_file: Array<Public_File>;
  /** fetch aggregated fields from the table: "public_file" */
  public_file_aggregate: Public_File_Aggregate;
  /** fetch data from the table: "public_file" using primary key columns */
  public_file_by_pk?: Maybe<Public_File>;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk?: Maybe<Skill>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Subscription_RootAnnouncementArgs = {
  distinct_on?: InputMaybe<Array<Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Announcement_Order_By>>;
  where?: InputMaybe<Announcement_Bool_Exp>;
};


export type Subscription_RootAnnouncement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Announcement_Order_By>>;
  where?: InputMaybe<Announcement_Bool_Exp>;
};


export type Subscription_RootAnnouncement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBlogArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBookingsArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEnrollmentArgs = {
  distinct_on?: InputMaybe<Array<Enrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollment_Order_By>>;
  where?: InputMaybe<Enrollment_Bool_Exp>;
};


export type Subscription_RootEnrollment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enrollment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enrollment_Order_By>>;
  where?: InputMaybe<Enrollment_Bool_Exp>;
};


export type Subscription_RootEnrollment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPractitionerArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_Order_By>>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
};


export type Subscription_RootPractitioner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_Order_By>>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
};


export type Subscription_RootPractitioner_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPractitioner_To_SkillArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


export type Subscription_RootPractitioner_To_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practitioner_To_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practitioner_To_Skill_Order_By>>;
  where?: InputMaybe<Practitioner_To_Skill_Bool_Exp>;
};


export type Subscription_RootPractitioner_To_Skill_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPressArgs = {
  distinct_on?: InputMaybe<Array<Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Press_Order_By>>;
  where?: InputMaybe<Press_Bool_Exp>;
};


export type Subscription_RootPress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Press_Order_By>>;
  where?: InputMaybe<Press_Bool_Exp>;
};


export type Subscription_RootPress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProfile_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Profile_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Review_Order_By>>;
  where?: InputMaybe<Profile_Review_Bool_Exp>;
};


export type Subscription_RootProfile_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Review_Order_By>>;
  where?: InputMaybe<Profile_Review_Bool_Exp>;
};


export type Subscription_RootProfile_Review_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPublic_FileArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


export type Subscription_RootPublic_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_File_Order_By>>;
  where?: InputMaybe<Public_File_Bool_Exp>;
};


export type Subscription_RootPublic_File_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};


export type Subscription_RootSkill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};


export type Subscription_RootSkill_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  announcements_last_seen_at: Scalars['timestamptz'];
  /** An array relationship */
  blogs: Array<Blog>;
  /** An aggregate relationship */
  blogs_aggregate: Blog_Aggregate;
  /** An array relationship */
  bookings: Array<Bookings>;
  /** An aggregate relationship */
  bookings_aggregate: Bookings_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display_name: Scalars['String'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  gender: Scalars['jsonb'];
  id: Scalars['Int'];
  last_logged_in_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  role: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "user" */
export type UserBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBlogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBookingsArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBookings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserGenderArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  announcements_last_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  blogs?: InputMaybe<Blog_Bool_Exp>;
  bookings?: InputMaybe<Bookings_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_logged_in_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  Idx_87102a93784486b32f39aefc93 = 'IDX_87102a93784486b32f39aefc93',
  /** unique or primary key constraint */
  PkCace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  gender?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  gender?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  gender?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  announcements_last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  blogs?: InputMaybe<Blog_Arr_Rel_Insert_Input>;
  bookings?: InputMaybe<Bookings_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  last_logged_in_at?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Profile_Obj_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  announcements_last_seen_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_logged_in_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  announcements_last_seen_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_logged_in_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  announcements_last_seen_at?: InputMaybe<Order_By>;
  blogs_aggregate?: InputMaybe<Blog_Aggregate_Order_By>;
  bookings_aggregate?: InputMaybe<Bookings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_logged_in_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  gender?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AnnouncementsLastSeenAt = 'announcements_last_seen_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastLoggedInAt = 'last_logged_in_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  announcements_last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  last_logged_in_at?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AnnouncementsLastSeenAt = 'announcements_last_seen_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastLoggedInAt = 'last_logged_in_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type ApprovePractitionerProfileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApprovePractitionerProfileMutation = { __typename?: 'mutation_root', update_practitioner_by_pk?: { __typename?: 'practitioner', id: number, created_at: any, updated_at: any } | null };

export type CreateAnnouncementMutationVariables = Exact<{
  title: Scalars['String'];
  message: Scalars['String'];
  authorId: Scalars['Int'];
  audience?: InputMaybe<Scalars['String']>;
}>;


export type CreateAnnouncementMutation = { __typename?: 'mutation_root', insert_announcement_one?: { __typename?: 'announcement', id: number, title: string, message: string, created_by_id: number, audience: string } | null };

export type CreateBlogMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type CreateBlogMutation = { __typename?: 'mutation_root', insert_blog_one?: { __typename?: 'blog', id: number, title: string, body: string } | null };

export type CreateBlogAdminMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
  author_id?: InputMaybe<Scalars['Int']>;
}>;


export type CreateBlogAdminMutation = { __typename?: 'mutation_root', insert_blog_one?: { __typename?: 'blog', id: number, title: string, body: string } | null };

export type CreatePressMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
  video?: InputMaybe<Scalars['String']>;
  show_more_url?: InputMaybe<Scalars['String']>;
  show_more_text?: InputMaybe<Scalars['String']>;
  audio?: InputMaybe<Scalars['String']>;
}>;


export type CreatePressMutation = { __typename?: 'mutation_root', insert_press_one?: { __typename?: 'press', id: number, title: string } | null };

export type CreateSkillMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}>;


export type CreateSkillMutation = { __typename?: 'mutation_root', insert_skill_one?: { __typename?: 'skill', id: number, name: string, status: string } | null };

export type CreateUserMutationVariables = Exact<{
  display_name?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  last_name?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: number } | null };

export type DeleteAnnouncementByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnnouncementByIdMutation = { __typename?: 'mutation_root', update_announcement_by_pk?: { __typename?: 'announcement', id: number } | null };

export type DeleteBlogByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBlogByIdMutation = { __typename?: 'mutation_root', update_blog_by_pk?: { __typename?: 'blog', id: number } | null };

export type DeleteClientByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteClientByIdMutation = { __typename?: 'mutation_root', update_client_by_pk?: { __typename?: 'client', id: number } | null };

export type DeletePractitionerByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePractitionerByIdMutation = { __typename?: 'mutation_root', update_practitioner_by_pk?: { __typename?: 'practitioner', id: number } | null };

export type DeletePressByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePressByIdMutation = { __typename?: 'mutation_root', update_press_by_pk?: { __typename?: 'press', id: number } | null };

export type DeletePublicFileByKeyMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DeletePublicFileByKeyMutation = { __typename?: 'mutation_root', deletePublicFileByKey: boolean };

export type DeleteUserByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserByIdMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: number } | null };

export type DeleteSkillByIdMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSkillByIdMutation = { __typename?: 'mutation_root', update_skill_by_pk?: { __typename?: 'skill', id: number } | null };

export type InitiateResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type InitiateResetPasswordMutation = { __typename?: 'mutation_root', initiatePasswordReset: { __typename?: 'Success', result: boolean } };

export type InsertClientMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  gender?: InputMaybe<Scalars['jsonb']>;
  email: Scalars['String'];
  display_name: Scalars['String'];
  location: Scalars['String'];
  profile_picture_id?: InputMaybe<Scalars['Int']>;
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
  password: Scalars['String'];
}>;


export type InsertClientMutation = { __typename?: 'mutation_root', insert_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null };

export type InsertPractitionerMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  gender?: InputMaybe<Scalars['jsonb']>;
  email: Scalars['String'];
  display_name: Scalars['String'];
  announcements_last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  profile_picture_id?: InputMaybe<Scalars['Int']>;
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
  password?: InputMaybe<Scalars['String']>;
  about?: InputMaybe<Scalars['String']>;
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  area?: InputMaybe<Scalars['String']>;
  booking_link?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  in_call_rate?: InputMaybe<Scalars['numeric']>;
  is_certified?: InputMaybe<Scalars['Boolean']>;
  is_trained?: InputMaybe<Scalars['Boolean']>;
  out_call_rate?: InputMaybe<Scalars['numeric']>;
  session_information?: InputMaybe<Scalars['String']>;
  short_bio?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  virtual_rate?: InputMaybe<Scalars['numeric']>;
  zip_code?: InputMaybe<Scalars['String']>;
}>;


export type InsertPractitionerMutation = { __typename?: 'mutation_root', insert_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null };

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  username: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', logIn: { __typename?: 'AuthTokens', accessToken: string, user: { __typename?: 'PartialUser', display_name: string, email: string, first_name: string, gender: Array<string>, id: number, last_name: string, role: string } } };

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'mutation_root', resetPassword: { __typename?: 'Success', result: boolean } };

export type ReviewProfileMutationVariables = Exact<{
  comment?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['Int']>;
}>;


export type ReviewProfileMutation = { __typename?: 'mutation_root', insert_profile_review_one?: { __typename?: 'profile_review', id: number } | null };

export type UpdateAnnouncementByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  message: Scalars['String'];
  audience: Scalars['String'];
}>;


export type UpdateAnnouncementByIdMutation = { __typename?: 'mutation_root', update_announcement_by_pk?: { __typename?: 'announcement', id: number, created_at: any, updated_at: any, title: string, message: string } | null };

export type UpdateBlogByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type UpdateBlogByIdMutation = { __typename?: 'mutation_root', update_blog_by_pk?: { __typename?: 'blog', id: number, created_at: any, updated_at: any, title: string, body: string } | null };

export type UpdateClientByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  appointment_type_preference?: InputMaybe<Scalars['jsonb']>;
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_status?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  user_status?: InputMaybe<Scalars['String']>;
}>;


export type UpdateClientByIdMutation = { __typename?: 'mutation_root', update_client_by_pk?: { __typename?: 'client', id: number, created_at: any, updated_at: any, appointment_type_preference?: any | null } | null, update_profile?: { __typename?: 'profile_mutation_response', returning: Array<{ __typename?: 'profile', status: string, location?: string | null }> } | null, update_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', display_name: string, email: string, first_name?: string | null, last_name?: string | null, gender: any, status: string }> } | null };

export type UpdatePractitionerByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_status?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  booking_link?: InputMaybe<Scalars['String']>;
  short_bio?: InputMaybe<Scalars['String']>;
  session_information?: InputMaybe<Scalars['String']>;
  is_trained?: InputMaybe<Scalars['Boolean']>;
  is_certified?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  appointment_type?: InputMaybe<Scalars['jsonb']>;
  about?: InputMaybe<Scalars['String']>;
  skills: Array<Practitioner_To_Skill_Insert_Input> | Practitioner_To_Skill_Insert_Input;
  user_status?: InputMaybe<Scalars['String']>;
  in_call_rate?: InputMaybe<Scalars['numeric']>;
  out_call_rate?: InputMaybe<Scalars['numeric']>;
  virtual_rate?: InputMaybe<Scalars['numeric']>;
  fields_to_approve?: InputMaybe<Scalars['jsonb']>;
  images?: InputMaybe<Array<Public_File_Insert_Input> | Public_File_Insert_Input>;
  booking_questions?: InputMaybe<Scalars['jsonb']>;
}>;


export type UpdatePractitionerByIdMutation = { __typename?: 'mutation_root', update_practitioner_by_pk?: { __typename?: 'practitioner', id: number, created_at: any, updated_at: any, fields_to_approve: any } | null, update_profile?: { __typename?: 'profile_mutation_response', returning: Array<{ __typename?: 'profile', status: string }> } | null, update_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', display_name: string, email: string, first_name?: string | null, last_name?: string | null, gender: any, status: string }> } | null, delete_practitioner_to_skill?: { __typename?: 'practitioner_to_skill_mutation_response', affected_rows: number } | null, insert_practitioner_to_skill?: { __typename?: 'practitioner_to_skill_mutation_response', affected_rows: number } | null, insert_public_file?: { __typename?: 'public_file_mutation_response', affected_rows: number } | null };

export type UpdatePressByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  audio?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  show_more_text?: InputMaybe<Scalars['String']>;
  show_more_url?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePressByIdMutation = { __typename?: 'mutation_root', update_press_by_pk?: { __typename?: 'press', id: number, created_at: any, updated_at: any, title: string, body: string, show_more_text?: string | null, show_more_url?: string | null, video?: string | null, audio?: string | null } | null };

export type UpdateSkillByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}>;


export type UpdateSkillByIdMutation = { __typename?: 'mutation_root', update_skill_by_pk?: { __typename?: 'skill', id: number, created_at: any, updated_at: any, name: string, status: string } | null };

export type UpdateUserByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  display_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['jsonb']>;
  last_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserByIdMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', created_at: any, display_name: string, email: string, first_name?: string | null, gender: any, id: number, last_name?: string | null, role: string, status: string, updated_at: any } | null };

export type AnnouncementQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AnnouncementQuery = { __typename?: 'query_root', announcement_by_pk?: { __typename?: 'announcement', id: number, created_at: any, updated_at: any, title: string, message: string, audience: string, author: { __typename?: 'user', display_name: string } } | null };

export type AnnouncementCountQueryVariables = Exact<{ [key: string]: never; }>;


export type AnnouncementCountQuery = { __typename?: 'query_root', announcement_aggregate: { __typename?: 'announcement_aggregate', aggregate?: { __typename?: 'announcement_aggregate_fields', count: number } | null } };

export type AnnouncementsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  title_order?: InputMaybe<Order_By>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  message_order?: InputMaybe<Order_By>;
  display_name_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Announcement_Bool_Exp>;
}>;


export type AnnouncementsQuery = { __typename?: 'query_root', announcement_aggregate: { __typename?: 'announcement_aggregate', nodes: Array<{ __typename?: 'announcement', id: number, created_at: any, deleted_at?: any | null, updated_at: any, title: string, message: string, audience: string, author: { __typename?: 'user', display_name: string } }>, aggregate?: { __typename?: 'announcement_aggregate_fields', count: number } | null } };

export type BlogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BlogQuery = { __typename?: 'query_root', blog_by_pk?: { __typename?: 'blog', id: number, created_at: any, updated_at: any, title: string, body: string, author: { __typename?: 'user', display_name: string } } | null };

export type BlogCountQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogCountQuery = { __typename?: 'query_root', blog_aggregate: { __typename?: 'blog_aggregate', aggregate?: { __typename?: 'blog_aggregate_fields', count: number } | null } };

export type BlogsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  title_order?: InputMaybe<Order_By>;
  author_order?: InputMaybe<Order_By>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Blog_Bool_Exp>;
}>;


export type BlogsQuery = { __typename?: 'query_root', blog_aggregate: { __typename?: 'blog_aggregate', nodes: Array<{ __typename?: 'blog', id: number, created_at: any, deleted_at?: any | null, updated_at: any, body: string, title: string, author: { __typename?: 'user', display_name: string } }>, aggregate?: { __typename?: 'blog_aggregate_fields', count: number } | null } };

export type ClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ClientQuery = { __typename?: 'query_root', client_by_pk?: { __typename?: 'client', id: number, created_at: any, updated_at: any, appointment_type_preference?: any | null, profile: { __typename?: 'profile', location?: string | null, status: string, owner: { __typename?: 'user', email: string, display_name: string, gender: any, first_name?: string | null, last_name?: string | null, status: string }, profile_picture?: { __typename?: 'public_file', url: string } | null } } | null };

export type ClientCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientCountQuery = { __typename?: 'query_root', client_aggregate: { __typename?: 'client_aggregate', aggregate?: { __typename?: 'client_aggregate_fields', count: number } | null } };

export type ClientsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Client_Bool_Exp>;
  appointment_type_preference_order?: InputMaybe<Order_By>;
  location_order?: InputMaybe<Order_By>;
  status_order?: InputMaybe<Order_By>;
  email_order?: InputMaybe<Order_By>;
  display_name_order?: InputMaybe<Order_By>;
  gender_order?: InputMaybe<Order_By>;
}>;


export type ClientsQuery = { __typename?: 'query_root', client_aggregate: { __typename?: 'client_aggregate', nodes: Array<{ __typename?: 'client', id: number, created_at: any, updated_at: any, appointment_type_preference?: any | null, profile: { __typename?: 'profile', location?: string | null, status: string, owner: { __typename?: 'user', email: string, display_name: string, gender: any } } }>, aggregate?: { __typename?: 'client_aggregate_fields', count: number } | null } };

export type EnrollmentCountQueryVariables = Exact<{ [key: string]: never; }>;


export type EnrollmentCountQuery = { __typename?: 'query_root', enrollment_aggregate: { __typename?: 'enrollment_aggregate', aggregate?: { __typename?: 'enrollment_aggregate_fields', count: number } | null } };

export type EnrollmentsQueryVariables = Exact<{
  where?: InputMaybe<Enrollment_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  email_order?: InputMaybe<Order_By>;
  created_at_order?: InputMaybe<Order_By>;
}>;


export type EnrollmentsQuery = { __typename?: 'query_root', enrollment_aggregate: { __typename?: 'enrollment_aggregate', nodes: Array<{ __typename?: 'enrollment', id: number, city: string, country: string, created_at: any, email: string, first_name: string, last_name: string, heard_about_us_at?: string | null, order_id: string, primary_phone_number?: string | null, state_or_province: string, updated_at: any }>, aggregate?: { __typename?: 'enrollment_aggregate_fields', count: number } | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'query_root', me: { __typename?: 'PartialUser', display_name: string, email: string, first_name: string, gender: Array<string>, id: number, last_name: string, role: string } };

export type PractitionerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PractitionerQuery = { __typename?: 'query_root', practitioner_by_pk?: { __typename?: 'practitioner', id: number, created_at: any, updated_at: any, about?: string | null, appointment_type?: any | null, area: string, city: string, zip_code?: string | null, is_certified: boolean, is_trained: boolean, session_information?: string | null, in_call_rate: any, out_call_rate: any, virtual_rate: any, short_bio?: string | null, booking_link?: string | null, booking_questions: any, fields_to_approve: any, status: string, pictures: Array<{ __typename?: 'public_file', id: number, key: string, url: string }>, skills: Array<{ __typename?: 'practitioner_to_skill', skill: { __typename?: 'skill', id: number, name: string } }>, profile: { __typename?: 'profile', id: number, location?: string | null, status: string, owner: { __typename?: 'user', email: string, display_name: string, gender: any, first_name?: string | null, last_name?: string | null, status: string }, profile_picture?: { __typename?: 'public_file', url: string } | null } } | null };

export type PractitionerCountQueryVariables = Exact<{ [key: string]: never; }>;


export type PractitionerCountQuery = { __typename?: 'query_root', practitioner_aggregate: { __typename?: 'practitioner_aggregate', aggregate?: { __typename?: 'practitioner_aggregate_fields', count: number } | null } };

export type PractitionersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Practitioner_Bool_Exp>;
  location_order?: InputMaybe<Order_By>;
  status_order?: InputMaybe<Order_By>;
  email_order?: InputMaybe<Order_By>;
  display_name_order?: InputMaybe<Order_By>;
  gender_order?: InputMaybe<Order_By>;
  is_certified_order?: InputMaybe<Order_By>;
  is_trained_order?: InputMaybe<Order_By>;
}>;


export type PractitionersQuery = { __typename?: 'query_root', practitioner_aggregate: { __typename?: 'practitioner_aggregate', nodes: Array<{ __typename?: 'practitioner', id: number, created_at: any, updated_at: any, status: string, fields_to_approve: any, is_certified: boolean, is_trained: boolean, profile: { __typename?: 'profile', location?: string | null, owner: { __typename?: 'user', email: string, display_name: string, gender: any } } }>, aggregate?: { __typename?: 'practitioner_aggregate_fields', count: number } | null } };

export type PressQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PressQuery = { __typename?: 'query_root', press_by_pk?: { __typename?: 'press', id: number, created_at: any, updated_at: any, title: string, body: string, audio?: string | null, video?: string | null, show_more_text?: string | null, show_more_url?: string | null, featured_image?: { __typename?: 'public_file', url: string } | null } | null };

export type PressCountQueryVariables = Exact<{ [key: string]: never; }>;


export type PressCountQuery = { __typename?: 'query_root', press_aggregate: { __typename?: 'press_aggregate', aggregate?: { __typename?: 'press_aggregate_fields', count: number } | null } };

export type PressesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  title_order?: InputMaybe<Order_By>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  body_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Press_Bool_Exp>;
}>;


export type PressesQuery = { __typename?: 'query_root', press_aggregate: { __typename?: 'press_aggregate', nodes: Array<{ __typename?: 'press', id: number, created_at: any, deleted_at?: any | null, updated_at: any, title: string }>, aggregate?: { __typename?: 'press_aggregate_fields', count: number } | null } };

export type PractitionerReviewsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type PractitionerReviewsQuery = { __typename?: 'query_root', profile_review: Array<{ __typename?: 'profile_review', id: number, comment: string, created_at: any, deleted_at?: any | null, reviewer?: { __typename?: 'user', display_name: string, id: number } | null, profile?: { __typename?: 'profile', owner: { __typename?: 'user', display_name: string } } | null }> };

export type SkillQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SkillQuery = { __typename?: 'query_root', skill_by_pk?: { __typename?: 'skill', id: number, created_at: any, updated_at: any, name: string, status: string } | null };

export type SkillCountQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillCountQuery = { __typename?: 'query_root', skill_aggregate: { __typename?: 'skill_aggregate', aggregate?: { __typename?: 'skill_aggregate_fields', count: number } | null } };

export type SkillsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  created_at_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  status_order?: InputMaybe<Order_By>;
  name_order?: InputMaybe<Order_By>;
  where?: InputMaybe<Skill_Bool_Exp>;
}>;


export type SkillsQuery = { __typename?: 'query_root', skill_aggregate: { __typename?: 'skill_aggregate', nodes: Array<{ __typename?: 'skill', id: number, created_at: any, deleted_at?: any | null, updated_at: any, name: string, status: string }>, aggregate?: { __typename?: 'skill_aggregate_fields', count: number } | null } };

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', created_at: any, display_name: string, email: string, first_name?: string | null, gender: any, id: number, last_name?: string | null, role: string, status: string, updated_at: any } | null };

export type UsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  created_at_order?: InputMaybe<Order_By>;
  display_name_order?: InputMaybe<Order_By>;
  email_order?: InputMaybe<Order_By>;
  first_name_order?: InputMaybe<Order_By>;
  gender_order?: InputMaybe<Order_By>;
  last_name_order?: InputMaybe<Order_By>;
  updated_at_order?: InputMaybe<Order_By>;
  role_order?: InputMaybe<Order_By>;
  status_order?: InputMaybe<Order_By>;
  id_order?: InputMaybe<Order_By>;
  where?: InputMaybe<User_Bool_Exp>;
}>;


export type UsersQuery = { __typename?: 'query_root', user_aggregate: { __typename?: 'user_aggregate', nodes: Array<{ __typename?: 'user', created_at: any, deleted_at?: any | null, display_name: string, email: string, first_name?: string | null, gender: any, id: number, last_name?: string | null, role: string, status: string, updated_at: any }>, aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null } };

export type UsersCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersCountQuery = { __typename?: 'query_root', user_aggregate: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null } };


export const ApprovePractitionerProfileDocument = gql`
    mutation approvePractitionerProfile($id: Int!) {
  update_practitioner_by_pk(pk_columns: {id: $id}, _set: {status: "Verified"}) {
    id
    created_at
    updated_at
  }
}
    `;
export type ApprovePractitionerProfileMutationFn = Apollo.MutationFunction<ApprovePractitionerProfileMutation, ApprovePractitionerProfileMutationVariables>;

/**
 * __useApprovePractitionerProfileMutation__
 *
 * To run a mutation, you first call `useApprovePractitionerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePractitionerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePractitionerProfileMutation, { data, loading, error }] = useApprovePractitionerProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApprovePractitionerProfileMutation(baseOptions?: Apollo.MutationHookOptions<ApprovePractitionerProfileMutation, ApprovePractitionerProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApprovePractitionerProfileMutation, ApprovePractitionerProfileMutationVariables>(ApprovePractitionerProfileDocument, options);
      }
export type ApprovePractitionerProfileMutationHookResult = ReturnType<typeof useApprovePractitionerProfileMutation>;
export type ApprovePractitionerProfileMutationResult = Apollo.MutationResult<ApprovePractitionerProfileMutation>;
export type ApprovePractitionerProfileMutationOptions = Apollo.BaseMutationOptions<ApprovePractitionerProfileMutation, ApprovePractitionerProfileMutationVariables>;
export const CreateAnnouncementDocument = gql`
    mutation createAnnouncement($title: String!, $message: String!, $authorId: Int!, $audience: String = "practitioner") {
  insert_announcement_one(
    object: {title: $title, message: $message, created_by_id: $authorId, audience: $audience}
  ) {
    id
    title
    message
    created_by_id
    audience
  }
}
    `;
export type CreateAnnouncementMutationFn = Apollo.MutationFunction<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      title: // value for 'title'
 *      message: // value for 'message'
 *      authorId: // value for 'authorId'
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>(CreateAnnouncementDocument, options);
      }
export type CreateAnnouncementMutationHookResult = ReturnType<typeof useCreateAnnouncementMutation>;
export type CreateAnnouncementMutationResult = Apollo.MutationResult<CreateAnnouncementMutation>;
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;
export const CreateBlogDocument = gql`
    mutation createBlog($title: String!, $body: String!) {
  insert_blog_one(object: {title: $title, body: $body}) {
    id
    title
    body
  }
}
    `;
export type CreateBlogMutationFn = Apollo.MutationFunction<CreateBlogMutation, CreateBlogMutationVariables>;

/**
 * __useCreateBlogMutation__
 *
 * To run a mutation, you first call `useCreateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogMutation, { data, loading, error }] = useCreateBlogMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBlogMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogMutation, CreateBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogMutation, CreateBlogMutationVariables>(CreateBlogDocument, options);
      }
export type CreateBlogMutationHookResult = ReturnType<typeof useCreateBlogMutation>;
export type CreateBlogMutationResult = Apollo.MutationResult<CreateBlogMutation>;
export type CreateBlogMutationOptions = Apollo.BaseMutationOptions<CreateBlogMutation, CreateBlogMutationVariables>;
export const CreateBlogAdminDocument = gql`
    mutation createBlogAdmin($title: String!, $body: String!, $author_id: Int) {
  insert_blog_one(object: {title: $title, body: $body, author_id: $author_id}) {
    id
    title
    body
  }
}
    `;
export type CreateBlogAdminMutationFn = Apollo.MutationFunction<CreateBlogAdminMutation, CreateBlogAdminMutationVariables>;

/**
 * __useCreateBlogAdminMutation__
 *
 * To run a mutation, you first call `useCreateBlogAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogAdminMutation, { data, loading, error }] = useCreateBlogAdminMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      author_id: // value for 'author_id'
 *   },
 * });
 */
export function useCreateBlogAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogAdminMutation, CreateBlogAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogAdminMutation, CreateBlogAdminMutationVariables>(CreateBlogAdminDocument, options);
      }
export type CreateBlogAdminMutationHookResult = ReturnType<typeof useCreateBlogAdminMutation>;
export type CreateBlogAdminMutationResult = Apollo.MutationResult<CreateBlogAdminMutation>;
export type CreateBlogAdminMutationOptions = Apollo.BaseMutationOptions<CreateBlogAdminMutation, CreateBlogAdminMutationVariables>;
export const CreatePressDocument = gql`
    mutation createPress($title: String!, $body: String!, $video: String = "", $show_more_url: String = "", $show_more_text: String = "", $audio: String = "") {
  insert_press_one(
    object: {title: $title, body: $body, video: $video, show_more_url: $show_more_url, show_more_text: $show_more_text, audio: $audio}
  ) {
    id
    title
  }
}
    `;
export type CreatePressMutationFn = Apollo.MutationFunction<CreatePressMutation, CreatePressMutationVariables>;

/**
 * __useCreatePressMutation__
 *
 * To run a mutation, you first call `useCreatePressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPressMutation, { data, loading, error }] = useCreatePressMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      video: // value for 'video'
 *      show_more_url: // value for 'show_more_url'
 *      show_more_text: // value for 'show_more_text'
 *      audio: // value for 'audio'
 *   },
 * });
 */
export function useCreatePressMutation(baseOptions?: Apollo.MutationHookOptions<CreatePressMutation, CreatePressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePressMutation, CreatePressMutationVariables>(CreatePressDocument, options);
      }
export type CreatePressMutationHookResult = ReturnType<typeof useCreatePressMutation>;
export type CreatePressMutationResult = Apollo.MutationResult<CreatePressMutation>;
export type CreatePressMutationOptions = Apollo.BaseMutationOptions<CreatePressMutation, CreatePressMutationVariables>;
export const CreateSkillDocument = gql`
    mutation createSkill($name: String = "", $status: String = "") {
  insert_skill_one(object: {name: $name, status: $status}) {
    id
    name
    status
  }
}
    `;
export type CreateSkillMutationFn = Apollo.MutationFunction<CreateSkillMutation, CreateSkillMutationVariables>;

/**
 * __useCreateSkillMutation__
 *
 * To run a mutation, you first call `useCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillMutation, { data, loading, error }] = useCreateSkillMutation({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<CreateSkillMutation, CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSkillMutation, CreateSkillMutationVariables>(CreateSkillDocument, options);
      }
export type CreateSkillMutationHookResult = ReturnType<typeof useCreateSkillMutation>;
export type CreateSkillMutationResult = Apollo.MutationResult<CreateSkillMutation>;
export type CreateSkillMutationOptions = Apollo.BaseMutationOptions<CreateSkillMutation, CreateSkillMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($display_name: String = "", $email: String!, $first_name: String = "", $gender: jsonb, $last_name: String = "", $role: String!, $password: String!) {
  insert_user_one(
    object: {announcements_last_seen_at: "now()", display_name: $display_name, email: $email, first_name: $first_name, gender: $gender, last_name: $last_name, role: $role, status: "Unverified", password: $password}
  ) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      display_name: // value for 'display_name'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      gender: // value for 'gender'
 *      last_name: // value for 'last_name'
 *      role: // value for 'role'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteAnnouncementByIdDocument = gql`
    mutation deleteAnnouncementById($id: Int!) {
  update_announcement_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteAnnouncementByIdMutationFn = Apollo.MutationFunction<DeleteAnnouncementByIdMutation, DeleteAnnouncementByIdMutationVariables>;

/**
 * __useDeleteAnnouncementByIdMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementByIdMutation, { data, loading, error }] = useDeleteAnnouncementByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnouncementByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnnouncementByIdMutation, DeleteAnnouncementByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnnouncementByIdMutation, DeleteAnnouncementByIdMutationVariables>(DeleteAnnouncementByIdDocument, options);
      }
export type DeleteAnnouncementByIdMutationHookResult = ReturnType<typeof useDeleteAnnouncementByIdMutation>;
export type DeleteAnnouncementByIdMutationResult = Apollo.MutationResult<DeleteAnnouncementByIdMutation>;
export type DeleteAnnouncementByIdMutationOptions = Apollo.BaseMutationOptions<DeleteAnnouncementByIdMutation, DeleteAnnouncementByIdMutationVariables>;
export const DeleteBlogByIdDocument = gql`
    mutation deleteBlogById($id: Int!) {
  update_blog_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteBlogByIdMutationFn = Apollo.MutationFunction<DeleteBlogByIdMutation, DeleteBlogByIdMutationVariables>;

/**
 * __useDeleteBlogByIdMutation__
 *
 * To run a mutation, you first call `useDeleteBlogByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogByIdMutation, { data, loading, error }] = useDeleteBlogByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogByIdMutation, DeleteBlogByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogByIdMutation, DeleteBlogByIdMutationVariables>(DeleteBlogByIdDocument, options);
      }
export type DeleteBlogByIdMutationHookResult = ReturnType<typeof useDeleteBlogByIdMutation>;
export type DeleteBlogByIdMutationResult = Apollo.MutationResult<DeleteBlogByIdMutation>;
export type DeleteBlogByIdMutationOptions = Apollo.BaseMutationOptions<DeleteBlogByIdMutation, DeleteBlogByIdMutationVariables>;
export const DeleteClientByIdDocument = gql`
    mutation deleteClientById($id: Int!) {
  update_client_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteClientByIdMutationFn = Apollo.MutationFunction<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>;

/**
 * __useDeleteClientByIdMutation__
 *
 * To run a mutation, you first call `useDeleteClientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientByIdMutation, { data, loading, error }] = useDeleteClientByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>(DeleteClientByIdDocument, options);
      }
export type DeleteClientByIdMutationHookResult = ReturnType<typeof useDeleteClientByIdMutation>;
export type DeleteClientByIdMutationResult = Apollo.MutationResult<DeleteClientByIdMutation>;
export type DeleteClientByIdMutationOptions = Apollo.BaseMutationOptions<DeleteClientByIdMutation, DeleteClientByIdMutationVariables>;
export const DeletePractitionerByIdDocument = gql`
    mutation deletePractitionerById($id: Int!) {
  update_practitioner_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeletePractitionerByIdMutationFn = Apollo.MutationFunction<DeletePractitionerByIdMutation, DeletePractitionerByIdMutationVariables>;

/**
 * __useDeletePractitionerByIdMutation__
 *
 * To run a mutation, you first call `useDeletePractitionerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePractitionerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePractitionerByIdMutation, { data, loading, error }] = useDeletePractitionerByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePractitionerByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeletePractitionerByIdMutation, DeletePractitionerByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePractitionerByIdMutation, DeletePractitionerByIdMutationVariables>(DeletePractitionerByIdDocument, options);
      }
export type DeletePractitionerByIdMutationHookResult = ReturnType<typeof useDeletePractitionerByIdMutation>;
export type DeletePractitionerByIdMutationResult = Apollo.MutationResult<DeletePractitionerByIdMutation>;
export type DeletePractitionerByIdMutationOptions = Apollo.BaseMutationOptions<DeletePractitionerByIdMutation, DeletePractitionerByIdMutationVariables>;
export const DeletePressByIdDocument = gql`
    mutation deletePressById($id: Int!) {
  update_press_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeletePressByIdMutationFn = Apollo.MutationFunction<DeletePressByIdMutation, DeletePressByIdMutationVariables>;

/**
 * __useDeletePressByIdMutation__
 *
 * To run a mutation, you first call `useDeletePressByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePressByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePressByIdMutation, { data, loading, error }] = useDeletePressByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePressByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeletePressByIdMutation, DeletePressByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePressByIdMutation, DeletePressByIdMutationVariables>(DeletePressByIdDocument, options);
      }
export type DeletePressByIdMutationHookResult = ReturnType<typeof useDeletePressByIdMutation>;
export type DeletePressByIdMutationResult = Apollo.MutationResult<DeletePressByIdMutation>;
export type DeletePressByIdMutationOptions = Apollo.BaseMutationOptions<DeletePressByIdMutation, DeletePressByIdMutationVariables>;
export const DeletePublicFileByKeyDocument = gql`
    mutation deletePublicFileByKey($key: String!) {
  deletePublicFileByKey(key: $key)
}
    `;
export type DeletePublicFileByKeyMutationFn = Apollo.MutationFunction<DeletePublicFileByKeyMutation, DeletePublicFileByKeyMutationVariables>;

/**
 * __useDeletePublicFileByKeyMutation__
 *
 * To run a mutation, you first call `useDeletePublicFileByKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicFileByKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicFileByKeyMutation, { data, loading, error }] = useDeletePublicFileByKeyMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeletePublicFileByKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicFileByKeyMutation, DeletePublicFileByKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicFileByKeyMutation, DeletePublicFileByKeyMutationVariables>(DeletePublicFileByKeyDocument, options);
      }
export type DeletePublicFileByKeyMutationHookResult = ReturnType<typeof useDeletePublicFileByKeyMutation>;
export type DeletePublicFileByKeyMutationResult = Apollo.MutationResult<DeletePublicFileByKeyMutation>;
export type DeletePublicFileByKeyMutationOptions = Apollo.BaseMutationOptions<DeletePublicFileByKeyMutation, DeletePublicFileByKeyMutationVariables>;
export const DeleteUserByIdDocument = gql`
    mutation deleteUserById($id: Int!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteUserByIdMutationFn = Apollo.MutationFunction<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>;

/**
 * __useDeleteUserByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserByIdMutation, { data, loading, error }] = useDeleteUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>(DeleteUserByIdDocument, options);
      }
export type DeleteUserByIdMutationHookResult = ReturnType<typeof useDeleteUserByIdMutation>;
export type DeleteUserByIdMutationResult = Apollo.MutationResult<DeleteUserByIdMutation>;
export type DeleteUserByIdMutationOptions = Apollo.BaseMutationOptions<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>;
export const DeleteSkillByIdDocument = gql`
    mutation deleteSkillById($id: Int!) {
  update_skill_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteSkillByIdMutationFn = Apollo.MutationFunction<DeleteSkillByIdMutation, DeleteSkillByIdMutationVariables>;

/**
 * __useDeleteSkillByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSkillByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillByIdMutation, { data, loading, error }] = useDeleteSkillByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSkillByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSkillByIdMutation, DeleteSkillByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSkillByIdMutation, DeleteSkillByIdMutationVariables>(DeleteSkillByIdDocument, options);
      }
export type DeleteSkillByIdMutationHookResult = ReturnType<typeof useDeleteSkillByIdMutation>;
export type DeleteSkillByIdMutationResult = Apollo.MutationResult<DeleteSkillByIdMutation>;
export type DeleteSkillByIdMutationOptions = Apollo.BaseMutationOptions<DeleteSkillByIdMutation, DeleteSkillByIdMutationVariables>;
export const InitiateResetPasswordDocument = gql`
    mutation initiateResetPassword($email: String!) {
  initiatePasswordReset(email: $email, env: "admin") {
    result
  }
}
    `;
export type InitiateResetPasswordMutationFn = Apollo.MutationFunction<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>;

/**
 * __useInitiateResetPasswordMutation__
 *
 * To run a mutation, you first call `useInitiateResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateResetPasswordMutation, { data, loading, error }] = useInitiateResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInitiateResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>(InitiateResetPasswordDocument, options);
      }
export type InitiateResetPasswordMutationHookResult = ReturnType<typeof useInitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationResult = Apollo.MutationResult<InitiateResetPasswordMutation>;
export type InitiateResetPasswordMutationOptions = Apollo.BaseMutationOptions<InitiateResetPasswordMutation, InitiateResetPasswordMutationVariables>;
export const InsertClientDocument = gql`
    mutation insertClient($first_name: String!, $last_name: String!, $gender: jsonb, $email: String!, $display_name: String!, $location: String!, $profile_picture_id: Int, $appointment_type_preference: jsonb = [], $password: String!) {
  insert_user(
    objects: {first_name: $first_name, last_name: $last_name, gender: $gender, email: $email, display_name: $display_name, role: "client", status: "Verified", announcements_last_seen_at: "now()", profile: {data: {status: "Active", location: $location, profile_picture_id: $profile_picture_id, client: {data: {appointment_type_preference: $appointment_type_preference}}}}, password: $password}
  ) {
    affected_rows
  }
}
    `;
export type InsertClientMutationFn = Apollo.MutationFunction<InsertClientMutation, InsertClientMutationVariables>;

/**
 * __useInsertClientMutation__
 *
 * To run a mutation, you first call `useInsertClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientMutation, { data, loading, error }] = useInsertClientMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      gender: // value for 'gender'
 *      email: // value for 'email'
 *      display_name: // value for 'display_name'
 *      location: // value for 'location'
 *      profile_picture_id: // value for 'profile_picture_id'
 *      appointment_type_preference: // value for 'appointment_type_preference'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useInsertClientMutation(baseOptions?: Apollo.MutationHookOptions<InsertClientMutation, InsertClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertClientMutation, InsertClientMutationVariables>(InsertClientDocument, options);
      }
export type InsertClientMutationHookResult = ReturnType<typeof useInsertClientMutation>;
export type InsertClientMutationResult = Apollo.MutationResult<InsertClientMutation>;
export type InsertClientMutationOptions = Apollo.BaseMutationOptions<InsertClientMutation, InsertClientMutationVariables>;
export const InsertPractitionerDocument = gql`
    mutation insertPractitioner($first_name: String!, $last_name: String!, $gender: jsonb, $email: String!, $display_name: String!, $announcements_last_seen_at: timestamptz = "now()", $profile_picture_id: Int, $appointment_type_preference: jsonb = "", $password: String = "", $about: String = "", $appointment_type: jsonb = "", $area: String = "", $booking_link: String = "", $city: String = "", $in_call_rate: numeric = "", $is_certified: Boolean = false, $is_trained: Boolean = false, $out_call_rate: numeric = "", $session_information: String = "", $short_bio: String = "", $slug: String = "", $virtual_rate: numeric = "", $zip_code: String = "") {
  insert_user(
    objects: {first_name: $first_name, last_name: $last_name, gender: $gender, email: $email, display_name: $display_name, role: "practitioner", status: "Verified", announcements_last_seen_at: $announcements_last_seen_at, profile: {data: {status: "Active", profile_picture_id: $profile_picture_id, practitioner: {data: {about: $about, appointment_type: $appointment_type, area: $area, booking_link: $booking_link, city: $city, in_call_rate: $in_call_rate, is_certified: $is_certified, is_trained: $is_trained, out_call_rate: $out_call_rate, session_information: $session_information, short_bio: $short_bio, slug: $slug, status: "Verified", virtual_rate: $virtual_rate, zip_code: $zip_code}}}}, password: $password}
  ) {
    affected_rows
  }
}
    `;
export type InsertPractitionerMutationFn = Apollo.MutationFunction<InsertPractitionerMutation, InsertPractitionerMutationVariables>;

/**
 * __useInsertPractitionerMutation__
 *
 * To run a mutation, you first call `useInsertPractitionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPractitionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPractitionerMutation, { data, loading, error }] = useInsertPractitionerMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      gender: // value for 'gender'
 *      email: // value for 'email'
 *      display_name: // value for 'display_name'
 *      announcements_last_seen_at: // value for 'announcements_last_seen_at'
 *      profile_picture_id: // value for 'profile_picture_id'
 *      appointment_type_preference: // value for 'appointment_type_preference'
 *      password: // value for 'password'
 *      about: // value for 'about'
 *      appointment_type: // value for 'appointment_type'
 *      area: // value for 'area'
 *      booking_link: // value for 'booking_link'
 *      city: // value for 'city'
 *      in_call_rate: // value for 'in_call_rate'
 *      is_certified: // value for 'is_certified'
 *      is_trained: // value for 'is_trained'
 *      out_call_rate: // value for 'out_call_rate'
 *      session_information: // value for 'session_information'
 *      short_bio: // value for 'short_bio'
 *      slug: // value for 'slug'
 *      virtual_rate: // value for 'virtual_rate'
 *      zip_code: // value for 'zip_code'
 *   },
 * });
 */
export function useInsertPractitionerMutation(baseOptions?: Apollo.MutationHookOptions<InsertPractitionerMutation, InsertPractitionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPractitionerMutation, InsertPractitionerMutationVariables>(InsertPractitionerDocument, options);
      }
export type InsertPractitionerMutationHookResult = ReturnType<typeof useInsertPractitionerMutation>;
export type InsertPractitionerMutationResult = Apollo.MutationResult<InsertPractitionerMutation>;
export type InsertPractitionerMutationOptions = Apollo.BaseMutationOptions<InsertPractitionerMutation, InsertPractitionerMutationVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $username: String!) {
  logIn(loginInput: {password: $password, username: $username}) {
    accessToken
    user {
      display_name
      email
      first_name
      gender
      id
      last_name
      role
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $resetPasswordInput) {
    result
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ReviewProfileDocument = gql`
    mutation reviewProfile($comment: String = "", $profile_id: Int = 10) {
  insert_profile_review_one(object: {comment: $comment, profile_id: $profile_id}) {
    id
  }
}
    `;
export type ReviewProfileMutationFn = Apollo.MutationFunction<ReviewProfileMutation, ReviewProfileMutationVariables>;

/**
 * __useReviewProfileMutation__
 *
 * To run a mutation, you first call `useReviewProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewProfileMutation, { data, loading, error }] = useReviewProfileMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useReviewProfileMutation(baseOptions?: Apollo.MutationHookOptions<ReviewProfileMutation, ReviewProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewProfileMutation, ReviewProfileMutationVariables>(ReviewProfileDocument, options);
      }
export type ReviewProfileMutationHookResult = ReturnType<typeof useReviewProfileMutation>;
export type ReviewProfileMutationResult = Apollo.MutationResult<ReviewProfileMutation>;
export type ReviewProfileMutationOptions = Apollo.BaseMutationOptions<ReviewProfileMutation, ReviewProfileMutationVariables>;
export const UpdateAnnouncementByIdDocument = gql`
    mutation updateAnnouncementById($id: Int!, $title: String!, $message: String!, $audience: String!) {
  update_announcement_by_pk(
    pk_columns: {id: $id}
    _set: {title: $title, message: $message, audience: $audience}
  ) {
    id
    created_at
    updated_at
    title
    message
  }
}
    `;
export type UpdateAnnouncementByIdMutationFn = Apollo.MutationFunction<UpdateAnnouncementByIdMutation, UpdateAnnouncementByIdMutationVariables>;

/**
 * __useUpdateAnnouncementByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementByIdMutation, { data, loading, error }] = useUpdateAnnouncementByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      message: // value for 'message'
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useUpdateAnnouncementByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnouncementByIdMutation, UpdateAnnouncementByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnouncementByIdMutation, UpdateAnnouncementByIdMutationVariables>(UpdateAnnouncementByIdDocument, options);
      }
export type UpdateAnnouncementByIdMutationHookResult = ReturnType<typeof useUpdateAnnouncementByIdMutation>;
export type UpdateAnnouncementByIdMutationResult = Apollo.MutationResult<UpdateAnnouncementByIdMutation>;
export type UpdateAnnouncementByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAnnouncementByIdMutation, UpdateAnnouncementByIdMutationVariables>;
export const UpdateBlogByIdDocument = gql`
    mutation updateBlogById($id: Int!, $title: String!, $body: String!) {
  update_blog_by_pk(pk_columns: {id: $id}, _set: {title: $title, body: $body}) {
    id
    created_at
    updated_at
    title
    body
  }
}
    `;
export type UpdateBlogByIdMutationFn = Apollo.MutationFunction<UpdateBlogByIdMutation, UpdateBlogByIdMutationVariables>;

/**
 * __useUpdateBlogByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBlogByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogByIdMutation, { data, loading, error }] = useUpdateBlogByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateBlogByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogByIdMutation, UpdateBlogByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogByIdMutation, UpdateBlogByIdMutationVariables>(UpdateBlogByIdDocument, options);
      }
export type UpdateBlogByIdMutationHookResult = ReturnType<typeof useUpdateBlogByIdMutation>;
export type UpdateBlogByIdMutationResult = Apollo.MutationResult<UpdateBlogByIdMutation>;
export type UpdateBlogByIdMutationOptions = Apollo.BaseMutationOptions<UpdateBlogByIdMutation, UpdateBlogByIdMutationVariables>;
export const UpdateClientByIdDocument = gql`
    mutation updateClientById($id: Int!, $appointment_type_preference: jsonb = null, $display_name: String = "", $email: String = "", $first_name: String = "", $gender: jsonb = [], $last_name: String = "", $profile_status: String = "", $location: String = "", $user_status: String = "") {
  update_client_by_pk(
    pk_columns: {id: $id}
    _set: {appointment_type_preference: $appointment_type_preference}
  ) {
    id
    created_at
    updated_at
    appointment_type_preference
  }
  update_profile(
    where: {client: {id: {_eq: $id}}}
    _set: {location: $location, status: $profile_status}
  ) {
    returning {
      status
      location
    }
  }
  update_user(
    where: {profile: {client: {id: {_eq: $id}}}}
    _set: {display_name: $display_name, email: $email, first_name: $first_name, gender: $gender, last_name: $last_name, status: $user_status}
  ) {
    returning {
      display_name
      email
      first_name
      last_name
      gender
      status
    }
  }
}
    `;
export type UpdateClientByIdMutationFn = Apollo.MutationFunction<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>;

/**
 * __useUpdateClientByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientByIdMutation, { data, loading, error }] = useUpdateClientByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      appointment_type_preference: // value for 'appointment_type_preference'
 *      display_name: // value for 'display_name'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      gender: // value for 'gender'
 *      last_name: // value for 'last_name'
 *      profile_status: // value for 'profile_status'
 *      location: // value for 'location'
 *      user_status: // value for 'user_status'
 *   },
 * });
 */
export function useUpdateClientByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>(UpdateClientByIdDocument, options);
      }
export type UpdateClientByIdMutationHookResult = ReturnType<typeof useUpdateClientByIdMutation>;
export type UpdateClientByIdMutationResult = Apollo.MutationResult<UpdateClientByIdMutation>;
export type UpdateClientByIdMutationOptions = Apollo.BaseMutationOptions<UpdateClientByIdMutation, UpdateClientByIdMutationVariables>;
export const UpdatePractitionerByIdDocument = gql`
    mutation updatePractitionerById($id: Int!, $display_name: String = "", $email: String = "", $first_name: String = "", $gender: jsonb = [], $last_name: String = "", $profile_status: String = "", $zip_code: String = "", $status: String = "", $booking_link: String = "", $short_bio: String = "", $session_information: String = "", $is_trained: Boolean = false, $is_certified: Boolean = false, $city: String = "", $area: String = "", $appointment_type: jsonb = "", $about: String = "", $skills: [practitioner_to_skill_insert_input!]!, $user_status: String = "", $in_call_rate: numeric = 0, $out_call_rate: numeric = 0, $virtual_rate: numeric = 0, $fields_to_approve: jsonb = {}, $images: [public_file_insert_input!] = {}, $booking_questions: jsonb = []) {
  update_practitioner_by_pk(
    pk_columns: {id: $id}
    _set: {about: $about, appointment_type: $appointment_type, area: $area, city: $city, is_certified: $is_certified, is_trained: $is_trained, booking_link: $booking_link, session_information: $session_information, short_bio: $short_bio, status: $status, zip_code: $zip_code, in_call_rate: $in_call_rate, out_call_rate: $out_call_rate, virtual_rate: $virtual_rate, fields_to_approve: $fields_to_approve, booking_questions: $booking_questions}
  ) {
    id
    created_at
    updated_at
    fields_to_approve
  }
  update_profile(
    where: {practitioner: {id: {_eq: $id}}}
    _set: {status: $profile_status}
  ) {
    returning {
      status
    }
  }
  update_user(
    where: {profile: {practitioner: {id: {_eq: $id}}}}
    _set: {display_name: $display_name, email: $email, first_name: $first_name, gender: $gender, last_name: $last_name, status: $user_status}
  ) {
    returning {
      display_name
      email
      first_name
      last_name
      gender
      status
    }
  }
  delete_practitioner_to_skill(where: {practitioner_id: {_eq: $id}}) {
    affected_rows
  }
  insert_practitioner_to_skill(objects: $skills) {
    affected_rows
  }
  insert_public_file(objects: $images) {
    affected_rows
  }
}
    `;
export type UpdatePractitionerByIdMutationFn = Apollo.MutationFunction<UpdatePractitionerByIdMutation, UpdatePractitionerByIdMutationVariables>;

/**
 * __useUpdatePractitionerByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePractitionerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePractitionerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePractitionerByIdMutation, { data, loading, error }] = useUpdatePractitionerByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      display_name: // value for 'display_name'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      gender: // value for 'gender'
 *      last_name: // value for 'last_name'
 *      profile_status: // value for 'profile_status'
 *      zip_code: // value for 'zip_code'
 *      status: // value for 'status'
 *      booking_link: // value for 'booking_link'
 *      short_bio: // value for 'short_bio'
 *      session_information: // value for 'session_information'
 *      is_trained: // value for 'is_trained'
 *      is_certified: // value for 'is_certified'
 *      city: // value for 'city'
 *      area: // value for 'area'
 *      appointment_type: // value for 'appointment_type'
 *      about: // value for 'about'
 *      skills: // value for 'skills'
 *      user_status: // value for 'user_status'
 *      in_call_rate: // value for 'in_call_rate'
 *      out_call_rate: // value for 'out_call_rate'
 *      virtual_rate: // value for 'virtual_rate'
 *      fields_to_approve: // value for 'fields_to_approve'
 *      images: // value for 'images'
 *      booking_questions: // value for 'booking_questions'
 *   },
 * });
 */
export function useUpdatePractitionerByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePractitionerByIdMutation, UpdatePractitionerByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePractitionerByIdMutation, UpdatePractitionerByIdMutationVariables>(UpdatePractitionerByIdDocument, options);
      }
export type UpdatePractitionerByIdMutationHookResult = ReturnType<typeof useUpdatePractitionerByIdMutation>;
export type UpdatePractitionerByIdMutationResult = Apollo.MutationResult<UpdatePractitionerByIdMutation>;
export type UpdatePractitionerByIdMutationOptions = Apollo.BaseMutationOptions<UpdatePractitionerByIdMutation, UpdatePractitionerByIdMutationVariables>;
export const UpdatePressByIdDocument = gql`
    mutation updatePressById($id: Int!, $title: String!, $audio: String = "", $body: String = "", $show_more_text: String = "", $show_more_url: String = "", $video: String = "") {
  update_press_by_pk(
    pk_columns: {id: $id}
    _set: {title: $title, audio: $audio, body: $body, show_more_text: $show_more_text, show_more_url: $show_more_url, video: $video}
  ) {
    id
    created_at
    updated_at
    title
    body
    show_more_text
    show_more_url
    video
    audio
  }
}
    `;
export type UpdatePressByIdMutationFn = Apollo.MutationFunction<UpdatePressByIdMutation, UpdatePressByIdMutationVariables>;

/**
 * __useUpdatePressByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePressByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePressByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePressByIdMutation, { data, loading, error }] = useUpdatePressByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      audio: // value for 'audio'
 *      body: // value for 'body'
 *      show_more_text: // value for 'show_more_text'
 *      show_more_url: // value for 'show_more_url'
 *      video: // value for 'video'
 *   },
 * });
 */
export function useUpdatePressByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePressByIdMutation, UpdatePressByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePressByIdMutation, UpdatePressByIdMutationVariables>(UpdatePressByIdDocument, options);
      }
export type UpdatePressByIdMutationHookResult = ReturnType<typeof useUpdatePressByIdMutation>;
export type UpdatePressByIdMutationResult = Apollo.MutationResult<UpdatePressByIdMutation>;
export type UpdatePressByIdMutationOptions = Apollo.BaseMutationOptions<UpdatePressByIdMutation, UpdatePressByIdMutationVariables>;
export const UpdateSkillByIdDocument = gql`
    mutation updateSkillById($id: Int!, $name: String = "", $status: String = "") {
  update_skill_by_pk(pk_columns: {id: $id}, _set: {name: $name, status: $status}) {
    id
    created_at
    updated_at
    name
    status
  }
}
    `;
export type UpdateSkillByIdMutationFn = Apollo.MutationFunction<UpdateSkillByIdMutation, UpdateSkillByIdMutationVariables>;

/**
 * __useUpdateSkillByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSkillByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillByIdMutation, { data, loading, error }] = useUpdateSkillByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSkillByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkillByIdMutation, UpdateSkillByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkillByIdMutation, UpdateSkillByIdMutationVariables>(UpdateSkillByIdDocument, options);
      }
export type UpdateSkillByIdMutationHookResult = ReturnType<typeof useUpdateSkillByIdMutation>;
export type UpdateSkillByIdMutationResult = Apollo.MutationResult<UpdateSkillByIdMutation>;
export type UpdateSkillByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSkillByIdMutation, UpdateSkillByIdMutationVariables>;
export const UpdateUserByIdDocument = gql`
    mutation updateUserById($id: Int!, $display_name: String, $email: String, $first_name: String, $gender: jsonb, $last_name: String, $role: String, $status: String) {
  update_user_by_pk(
    pk_columns: {id: $id}
    _set: {display_name: $display_name, email: $email, first_name: $first_name, gender: $gender, last_name: $last_name, role: $role, status: $status}
  ) {
    created_at
    display_name
    email
    first_name
    gender
    id
    last_name
    role
    status
    updated_at
  }
}
    `;
export type UpdateUserByIdMutationFn = Apollo.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      display_name: // value for 'display_name'
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      gender: // value for 'gender'
 *      last_name: // value for 'last_name'
 *      role: // value for 'role'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(UpdateUserByIdDocument, options);
      }
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = Apollo.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = Apollo.BaseMutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export const AnnouncementDocument = gql`
    query announcement($id: Int!) {
  announcement_by_pk(id: $id) {
    id
    created_at
    updated_at
    title
    message
    author {
      display_name
    }
    audience
  }
}
    `;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions: Apollo.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
      }
export function useAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = Apollo.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const AnnouncementCountDocument = gql`
    query announcementCount {
  announcement_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAnnouncementCountQuery__
 *
 * To run a query within a React component, call `useAnnouncementCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementCountQuery(baseOptions?: Apollo.QueryHookOptions<AnnouncementCountQuery, AnnouncementCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementCountQuery, AnnouncementCountQueryVariables>(AnnouncementCountDocument, options);
      }
export function useAnnouncementCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementCountQuery, AnnouncementCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementCountQuery, AnnouncementCountQueryVariables>(AnnouncementCountDocument, options);
        }
export type AnnouncementCountQueryHookResult = ReturnType<typeof useAnnouncementCountQuery>;
export type AnnouncementCountLazyQueryHookResult = ReturnType<typeof useAnnouncementCountLazyQuery>;
export type AnnouncementCountQueryResult = Apollo.QueryResult<AnnouncementCountQuery, AnnouncementCountQueryVariables>;
export const AnnouncementsDocument = gql`
    query announcements($limit: Int, $offset: Int, $title_order: order_by = null, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $message_order: order_by = null, $display_name_order: order_by = null, $where: announcement_bool_exp = {deleted_at: {_is_null: true}}) {
  announcement_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {title: $title_order, message: $message_order, id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order, author: {display_name: $display_name_order}}
  ) {
    nodes {
      id
      created_at
      deleted_at
      updated_at
      title
      message
      author {
        display_name
      }
      audience
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      title_order: // value for 'title_order'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      message_order: // value for 'message_order'
 *      display_name_order: // value for 'display_name_order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
      }
export function useAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
        }
export type AnnouncementsQueryHookResult = ReturnType<typeof useAnnouncementsQuery>;
export type AnnouncementsLazyQueryHookResult = ReturnType<typeof useAnnouncementsLazyQuery>;
export type AnnouncementsQueryResult = Apollo.QueryResult<AnnouncementsQuery, AnnouncementsQueryVariables>;
export const BlogDocument = gql`
    query blog($id: Int!) {
  blog_by_pk(id: $id) {
    id
    created_at
    updated_at
    title
    body
    author {
      display_name
    }
  }
}
    `;

/**
 * __useBlogQuery__
 *
 * To run a query within a React component, call `useBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlogQuery(baseOptions: Apollo.QueryHookOptions<BlogQuery, BlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogQuery, BlogQueryVariables>(BlogDocument, options);
      }
export function useBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogQuery, BlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogQuery, BlogQueryVariables>(BlogDocument, options);
        }
export type BlogQueryHookResult = ReturnType<typeof useBlogQuery>;
export type BlogLazyQueryHookResult = ReturnType<typeof useBlogLazyQuery>;
export type BlogQueryResult = Apollo.QueryResult<BlogQuery, BlogQueryVariables>;
export const BlogCountDocument = gql`
    query blogCount {
  blog_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBlogCountQuery__
 *
 * To run a query within a React component, call `useBlogCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogCountQuery(baseOptions?: Apollo.QueryHookOptions<BlogCountQuery, BlogCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogCountQuery, BlogCountQueryVariables>(BlogCountDocument, options);
      }
export function useBlogCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogCountQuery, BlogCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogCountQuery, BlogCountQueryVariables>(BlogCountDocument, options);
        }
export type BlogCountQueryHookResult = ReturnType<typeof useBlogCountQuery>;
export type BlogCountLazyQueryHookResult = ReturnType<typeof useBlogCountLazyQuery>;
export type BlogCountQueryResult = Apollo.QueryResult<BlogCountQuery, BlogCountQueryVariables>;
export const BlogsDocument = gql`
    query blogs($limit: Int, $offset: Int, $title_order: order_by = null, $author_order: order_by = null, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $where: blog_bool_exp = {deleted_at: {_is_null: true}}) {
  blog_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {author: {display_name: $author_order}, title: $title_order, id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order}
  ) {
    nodes {
      id
      created_at
      deleted_at
      updated_at
      body
      title
      author {
        display_name
      }
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBlogsQuery__
 *
 * To run a query within a React component, call `useBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      title_order: // value for 'title_order'
 *      author_order: // value for 'author_order'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBlogsQuery(baseOptions?: Apollo.QueryHookOptions<BlogsQuery, BlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogsQuery, BlogsQueryVariables>(BlogsDocument, options);
      }
export function useBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogsQuery, BlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogsQuery, BlogsQueryVariables>(BlogsDocument, options);
        }
export type BlogsQueryHookResult = ReturnType<typeof useBlogsQuery>;
export type BlogsLazyQueryHookResult = ReturnType<typeof useBlogsLazyQuery>;
export type BlogsQueryResult = Apollo.QueryResult<BlogsQuery, BlogsQueryVariables>;
export const ClientDocument = gql`
    query client($id: Int!) {
  client_by_pk(id: $id) {
    id
    created_at
    updated_at
    appointment_type_preference
    profile {
      location
      status
      owner {
        email
        display_name
        gender
        first_name
        last_name
        status
      }
      profile_picture {
        url
      }
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientCountDocument = gql`
    query clientCount {
  client_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useClientCountQuery__
 *
 * To run a query within a React component, call `useClientCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientCountQuery(baseOptions?: Apollo.QueryHookOptions<ClientCountQuery, ClientCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientCountQuery, ClientCountQueryVariables>(ClientCountDocument, options);
      }
export function useClientCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientCountQuery, ClientCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientCountQuery, ClientCountQueryVariables>(ClientCountDocument, options);
        }
export type ClientCountQueryHookResult = ReturnType<typeof useClientCountQuery>;
export type ClientCountLazyQueryHookResult = ReturnType<typeof useClientCountLazyQuery>;
export type ClientCountQueryResult = Apollo.QueryResult<ClientCountQuery, ClientCountQueryVariables>;
export const ClientsDocument = gql`
    query clients($limit: Int, $offset: Int, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $where: client_bool_exp = {deleted_at: {_is_null: true}}, $appointment_type_preference_order: order_by = null, $location_order: order_by = null, $status_order: order_by = null, $email_order: order_by = null, $display_name_order: order_by = null, $gender_order: order_by = null) {
  client_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order, appointment_type_preference: $appointment_type_preference_order, profile: {location: $location_order, status: $status_order, owner: {email: $email_order, display_name: $display_name_order, gender: $gender_order}}}
  ) {
    nodes {
      id
      created_at
      updated_at
      appointment_type_preference
      profile {
        location
        status
        owner {
          email
          display_name
          gender
        }
      }
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      where: // value for 'where'
 *      appointment_type_preference_order: // value for 'appointment_type_preference_order'
 *      location_order: // value for 'location_order'
 *      status_order: // value for 'status_order'
 *      email_order: // value for 'email_order'
 *      display_name_order: // value for 'display_name_order'
 *      gender_order: // value for 'gender_order'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const EnrollmentCountDocument = gql`
    query enrollmentCount {
  enrollment_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useEnrollmentCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnrollmentCountQuery(baseOptions?: Apollo.QueryHookOptions<EnrollmentCountQuery, EnrollmentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrollmentCountQuery, EnrollmentCountQueryVariables>(EnrollmentCountDocument, options);
      }
export function useEnrollmentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentCountQuery, EnrollmentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrollmentCountQuery, EnrollmentCountQueryVariables>(EnrollmentCountDocument, options);
        }
export type EnrollmentCountQueryHookResult = ReturnType<typeof useEnrollmentCountQuery>;
export type EnrollmentCountLazyQueryHookResult = ReturnType<typeof useEnrollmentCountLazyQuery>;
export type EnrollmentCountQueryResult = Apollo.QueryResult<EnrollmentCountQuery, EnrollmentCountQueryVariables>;
export const EnrollmentsDocument = gql`
    query enrollments($where: enrollment_bool_exp = {}, $limit: Int = 10, $offset: Int = 10, $email_order: order_by = asc, $created_at_order: order_by = asc) {
  enrollment_aggregate(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {email: $email_order, created_at: $created_at_order}
  ) {
    nodes {
      id
      city
      country
      created_at
      email
      first_name
      last_name
      heard_about_us_at
      order_id
      primary_phone_number
      state_or_province
      updated_at
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useEnrollmentsQuery__
 *
 * To run a query within a React component, call `useEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      email_order: // value for 'email_order'
 *      created_at_order: // value for 'created_at_order'
 *   },
 * });
 */
export function useEnrollmentsQuery(baseOptions?: Apollo.QueryHookOptions<EnrollmentsQuery, EnrollmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrollmentsQuery, EnrollmentsQueryVariables>(EnrollmentsDocument, options);
      }
export function useEnrollmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentsQuery, EnrollmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrollmentsQuery, EnrollmentsQueryVariables>(EnrollmentsDocument, options);
        }
export type EnrollmentsQueryHookResult = ReturnType<typeof useEnrollmentsQuery>;
export type EnrollmentsLazyQueryHookResult = ReturnType<typeof useEnrollmentsLazyQuery>;
export type EnrollmentsQueryResult = Apollo.QueryResult<EnrollmentsQuery, EnrollmentsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    display_name
    email
    first_name
    gender
    id
    last_name
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PractitionerDocument = gql`
    query practitioner($id: Int!) {
  practitioner_by_pk(id: $id) {
    id
    created_at
    updated_at
    about
    appointment_type
    area
    city
    zip_code
    is_certified
    is_trained
    session_information
    in_call_rate
    out_call_rate
    virtual_rate
    short_bio
    booking_link
    booking_questions
    fields_to_approve
    pictures {
      id
      key
      url
    }
    skills {
      skill {
        id
        name
      }
    }
    status
    profile {
      id
      location
      status
      owner {
        email
        display_name
        gender
        first_name
        last_name
        status
      }
      profile_picture {
        url
      }
    }
  }
}
    `;

/**
 * __usePractitionerQuery__
 *
 * To run a query within a React component, call `usePractitionerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePractitionerQuery(baseOptions: Apollo.QueryHookOptions<PractitionerQuery, PractitionerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PractitionerQuery, PractitionerQueryVariables>(PractitionerDocument, options);
      }
export function usePractitionerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PractitionerQuery, PractitionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PractitionerQuery, PractitionerQueryVariables>(PractitionerDocument, options);
        }
export type PractitionerQueryHookResult = ReturnType<typeof usePractitionerQuery>;
export type PractitionerLazyQueryHookResult = ReturnType<typeof usePractitionerLazyQuery>;
export type PractitionerQueryResult = Apollo.QueryResult<PractitionerQuery, PractitionerQueryVariables>;
export const PractitionerCountDocument = gql`
    query practitionerCount {
  practitioner_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePractitionerCountQuery__
 *
 * To run a query within a React component, call `usePractitionerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionerCountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePractitionerCountQuery(baseOptions?: Apollo.QueryHookOptions<PractitionerCountQuery, PractitionerCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PractitionerCountQuery, PractitionerCountQueryVariables>(PractitionerCountDocument, options);
      }
export function usePractitionerCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PractitionerCountQuery, PractitionerCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PractitionerCountQuery, PractitionerCountQueryVariables>(PractitionerCountDocument, options);
        }
export type PractitionerCountQueryHookResult = ReturnType<typeof usePractitionerCountQuery>;
export type PractitionerCountLazyQueryHookResult = ReturnType<typeof usePractitionerCountLazyQuery>;
export type PractitionerCountQueryResult = Apollo.QueryResult<PractitionerCountQuery, PractitionerCountQueryVariables>;
export const PractitionersDocument = gql`
    query practitioners($limit: Int, $offset: Int, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $where: practitioner_bool_exp = {deleted_at: {_is_null: true}}, $location_order: order_by = null, $status_order: order_by = null, $email_order: order_by = null, $display_name_order: order_by = null, $gender_order: order_by = null, $is_certified_order: order_by = null, $is_trained_order: order_by = null) {
  practitioner_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order, status: $status_order, profile: {location: $location_order, owner: {email: $email_order, display_name: $display_name_order, gender: $gender_order}}, is_trained: $is_trained_order, is_certified: $is_certified_order}
  ) {
    nodes {
      id
      created_at
      updated_at
      status
      fields_to_approve
      profile {
        location
        owner {
          email
          display_name
          gender
        }
      }
      is_certified
      is_trained
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePractitionersQuery__
 *
 * To run a query within a React component, call `usePractitionersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      where: // value for 'where'
 *      location_order: // value for 'location_order'
 *      status_order: // value for 'status_order'
 *      email_order: // value for 'email_order'
 *      display_name_order: // value for 'display_name_order'
 *      gender_order: // value for 'gender_order'
 *      is_certified_order: // value for 'is_certified_order'
 *      is_trained_order: // value for 'is_trained_order'
 *   },
 * });
 */
export function usePractitionersQuery(baseOptions?: Apollo.QueryHookOptions<PractitionersQuery, PractitionersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PractitionersQuery, PractitionersQueryVariables>(PractitionersDocument, options);
      }
export function usePractitionersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PractitionersQuery, PractitionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PractitionersQuery, PractitionersQueryVariables>(PractitionersDocument, options);
        }
export type PractitionersQueryHookResult = ReturnType<typeof usePractitionersQuery>;
export type PractitionersLazyQueryHookResult = ReturnType<typeof usePractitionersLazyQuery>;
export type PractitionersQueryResult = Apollo.QueryResult<PractitionersQuery, PractitionersQueryVariables>;
export const PressDocument = gql`
    query press($id: Int!) {
  press_by_pk(id: $id) {
    id
    created_at
    updated_at
    title
    body
    audio
    video
    featured_image {
      url
    }
    show_more_text
    show_more_url
  }
}
    `;

/**
 * __usePressQuery__
 *
 * To run a query within a React component, call `usePressQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePressQuery(baseOptions: Apollo.QueryHookOptions<PressQuery, PressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PressQuery, PressQueryVariables>(PressDocument, options);
      }
export function usePressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PressQuery, PressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PressQuery, PressQueryVariables>(PressDocument, options);
        }
export type PressQueryHookResult = ReturnType<typeof usePressQuery>;
export type PressLazyQueryHookResult = ReturnType<typeof usePressLazyQuery>;
export type PressQueryResult = Apollo.QueryResult<PressQuery, PressQueryVariables>;
export const PressCountDocument = gql`
    query pressCount {
  press_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePressCountQuery__
 *
 * To run a query within a React component, call `usePressCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressCountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePressCountQuery(baseOptions?: Apollo.QueryHookOptions<PressCountQuery, PressCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PressCountQuery, PressCountQueryVariables>(PressCountDocument, options);
      }
export function usePressCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PressCountQuery, PressCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PressCountQuery, PressCountQueryVariables>(PressCountDocument, options);
        }
export type PressCountQueryHookResult = ReturnType<typeof usePressCountQuery>;
export type PressCountLazyQueryHookResult = ReturnType<typeof usePressCountLazyQuery>;
export type PressCountQueryResult = Apollo.QueryResult<PressCountQuery, PressCountQueryVariables>;
export const PressesDocument = gql`
    query presses($limit: Int, $offset: Int, $title_order: order_by = null, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $body_order: order_by = null, $where: press_bool_exp = {deleted_at: {_is_null: true}}) {
  press_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order, title: $title_order}
  ) {
    nodes {
      id
      created_at
      deleted_at
      updated_at
      title
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePressesQuery__
 *
 * To run a query within a React component, call `usePressesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      title_order: // value for 'title_order'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      body_order: // value for 'body_order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePressesQuery(baseOptions?: Apollo.QueryHookOptions<PressesQuery, PressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PressesQuery, PressesQueryVariables>(PressesDocument, options);
      }
export function usePressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PressesQuery, PressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PressesQuery, PressesQueryVariables>(PressesDocument, options);
        }
export type PressesQueryHookResult = ReturnType<typeof usePressesQuery>;
export type PressesLazyQueryHookResult = ReturnType<typeof usePressesLazyQuery>;
export type PressesQueryResult = Apollo.QueryResult<PressesQuery, PressesQueryVariables>;
export const PractitionerReviewsDocument = gql`
    query practitionerReviews($id: Int) {
  profile_review(where: {profile_id: {_eq: $id}}) {
    id
    comment
    created_at
    deleted_at
    reviewer {
      display_name
      id
    }
    profile {
      owner {
        display_name
      }
    }
  }
}
    `;

/**
 * __usePractitionerReviewsQuery__
 *
 * To run a query within a React component, call `usePractitionerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionerReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePractitionerReviewsQuery(baseOptions?: Apollo.QueryHookOptions<PractitionerReviewsQuery, PractitionerReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PractitionerReviewsQuery, PractitionerReviewsQueryVariables>(PractitionerReviewsDocument, options);
      }
export function usePractitionerReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PractitionerReviewsQuery, PractitionerReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PractitionerReviewsQuery, PractitionerReviewsQueryVariables>(PractitionerReviewsDocument, options);
        }
export type PractitionerReviewsQueryHookResult = ReturnType<typeof usePractitionerReviewsQuery>;
export type PractitionerReviewsLazyQueryHookResult = ReturnType<typeof usePractitionerReviewsLazyQuery>;
export type PractitionerReviewsQueryResult = Apollo.QueryResult<PractitionerReviewsQuery, PractitionerReviewsQueryVariables>;
export const SkillDocument = gql`
    query skill($id: Int!) {
  skill_by_pk(id: $id) {
    id
    created_at
    updated_at
    name
    status
  }
}
    `;

/**
 * __useSkillQuery__
 *
 * To run a query within a React component, call `useSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillQuery(baseOptions: Apollo.QueryHookOptions<SkillQuery, SkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillQuery, SkillQueryVariables>(SkillDocument, options);
      }
export function useSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillQuery, SkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillQuery, SkillQueryVariables>(SkillDocument, options);
        }
export type SkillQueryHookResult = ReturnType<typeof useSkillQuery>;
export type SkillLazyQueryHookResult = ReturnType<typeof useSkillLazyQuery>;
export type SkillQueryResult = Apollo.QueryResult<SkillQuery, SkillQueryVariables>;
export const SkillCountDocument = gql`
    query skillCount {
  skill_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSkillCountQuery__
 *
 * To run a query within a React component, call `useSkillCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillCountQuery(baseOptions?: Apollo.QueryHookOptions<SkillCountQuery, SkillCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillCountQuery, SkillCountQueryVariables>(SkillCountDocument, options);
      }
export function useSkillCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillCountQuery, SkillCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillCountQuery, SkillCountQueryVariables>(SkillCountDocument, options);
        }
export type SkillCountQueryHookResult = ReturnType<typeof useSkillCountQuery>;
export type SkillCountLazyQueryHookResult = ReturnType<typeof useSkillCountLazyQuery>;
export type SkillCountQueryResult = Apollo.QueryResult<SkillCountQuery, SkillCountQueryVariables>;
export const SkillsDocument = gql`
    query skills($limit: Int = null, $offset: Int = null, $created_at_order: order_by = null, $id_order: order_by = null, $updated_at_order: order_by = null, $status_order: order_by = null, $name_order: order_by = null, $where: skill_bool_exp = {deleted_at: {_is_null: true}}) {
  skill_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {id: $id_order, created_at: $created_at_order, updated_at: $updated_at_order, name: $name_order, status: $status_order}
  ) {
    nodes {
      id
      created_at
      deleted_at
      updated_at
      name
      status
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      created_at_order: // value for 'created_at_order'
 *      id_order: // value for 'id_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      status_order: // value for 'status_order'
 *      name_order: // value for 'name_order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSkillsQuery(baseOptions?: Apollo.QueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
      }
export function useSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
        }
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = Apollo.QueryResult<SkillsQuery, SkillsQueryVariables>;
export const UserDocument = gql`
    query user($id: Int!) {
  user_by_pk(id: $id) {
    created_at
    display_name
    email
    first_name
    gender
    id
    last_name
    role
    status
    updated_at
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($limit: Int, $offset: Int, $created_at_order: order_by = null, $display_name_order: order_by = null, $email_order: order_by = null, $first_name_order: order_by = null, $gender_order: order_by = null, $last_name_order: order_by = null, $updated_at_order: order_by = null, $role_order: order_by = null, $status_order: order_by = null, $id_order: order_by = null, $where: user_bool_exp = {deleted_at: {_is_null: true}}) {
  user_aggregate(
    offset: $offset
    limit: $limit
    where: $where
    order_by: {created_at: $created_at_order, display_name: $display_name_order, email: $email_order, first_name: $first_name_order, gender: $gender_order, last_name: $last_name_order, updated_at: $updated_at_order, role: $role_order, status: $status_order, id: $id_order}
  ) {
    nodes {
      created_at
      deleted_at
      display_name
      email
      first_name
      gender
      id
      last_name
      role
      status
      updated_at
    }
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      created_at_order: // value for 'created_at_order'
 *      display_name_order: // value for 'display_name_order'
 *      email_order: // value for 'email_order'
 *      first_name_order: // value for 'first_name_order'
 *      gender_order: // value for 'gender_order'
 *      last_name_order: // value for 'last_name_order'
 *      updated_at_order: // value for 'updated_at_order'
 *      role_order: // value for 'role_order'
 *      status_order: // value for 'status_order'
 *      id_order: // value for 'id_order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UsersCountDocument = gql`
    query usersCount {
  user_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersCountQuery__
 *
 * To run a query within a React component, call `useUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersCountQuery(baseOptions?: Apollo.QueryHookOptions<UsersCountQuery, UsersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, options);
      }
export function useUsersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersCountQuery, UsersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, options);
        }
export type UsersCountQueryHookResult = ReturnType<typeof useUsersCountQuery>;
export type UsersCountLazyQueryHookResult = ReturnType<typeof useUsersCountLazyQuery>;
export type UsersCountQueryResult = Apollo.QueryResult<UsersCountQuery, UsersCountQueryVariables>;