import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { FieldError, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
  BlogCountDocument,
  BlogsDocument,
  useBlogQuery,
  useDeleteBlogByIdMutation,
  useUpdateBlogByIdMutation,
} from '../../../api/graphql';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import RichTextField from '../../../components/Inputs/RichTextField';
import BackButton from '../../../components/BackButton';

interface BlogEditForm {
  id: number;
  title: string;
  body: object;
}

const BlogEditSchema: yup.SchemaOf<BlogEditForm> = yup.object({
  id: yup.number().integer().required(),
  title: yup.string().min(4).required(),
  body: yup
    .object()
    .test({
      test: (value: any) => {
        if (value) {
          return value.getCurrentContent().getPlainText().length >= 140;
        }
        return false;
      },
      message: 'Body must be longer then 50 characters',
    })
    .required()
    .label('Body'),
});

const constructModalBody = (blog?: any) =>
  `Are you sure you want to remove the blog with title ${blog?.title}`;

const EditBlog: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: blog, loading } = useBlogQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    getFieldState,
  } = useForm<BlogEditForm>({
    resolver: yupResolver(BlogEditSchema),
    mode: 'onChange',
    defaultValues: {
      body: EditorState.createEmpty(),
      title: '',
    },
  });
  const { body } = useWatch({ control });
  const isEditorDirty = blog
    ? JSON.stringify(
        convertToRaw((body as EditorState).getCurrentContent())
      ) !== blog.blog_by_pk!.body
    : false;

  const { isDirty: isTitleDirty } = getFieldState('title');

  const [updateBlogByIdRequest, { loading: updateLoading }] =
    useUpdateBlogByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Blog successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [deleteBlogRequest, { loading: deleteBlogByIdLoading }] =
    useDeleteBlogByIdMutation({
      onCompleted: () => {
        setIsRemovingItem(false);
        SnackbarManager.addSnackbar({
          message: `Blog with ID ${blog?.blog_by_pk?.id} successfully deleted`,
          options: { variant: 'success' },
        });
        navigate('/dashboard/blogs');
      },
      onError: () => {
        setIsRemovingItem(false);
      },
      refetchQueries: [BlogsDocument, 'blogs', BlogCountDocument, 'blogCount'],
    });

  useEffect(() => {
    if (blog) {
      const { blog_by_pk } = blog;
      const parsed = {
        ...blog_by_pk,
        body: EditorState.createWithContent(
          convertFromRaw(JSON.parse(blog_by_pk!.body))
        ),
        created_at: format(parseISO(blog_by_pk?.created_at), 'MM-dd-yyyy'),
        updated_at: format(parseISO(blog_by_pk?.updated_at), 'MM-dd-yyyy'),
      };
      reset(parsed);
    }
  }, [blog?.blog_by_pk, loading, reset]);

  const updateBlog = (formData: BlogEditForm) => {
    const serializedData = {
      ...formData,
      body: JSON.stringify(
        convertToRaw((formData.body as EditorState).getCurrentContent())
      ),
    };
    updateBlogByIdRequest({
      variables: serializedData,
    });
  };

  const promptBlogDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deleteBlogRequest({ variables: { id: blog!.blog_by_pk!.id } });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <BackButton />

      <Box component="form" onSubmit={handleSubmit(updateBlog)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
          />
          <RichTextField
            name="body"
            label="Body"
            error={!!errors.body}
            helperText={(errors.body as FieldError)?.message}
            control={control}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || (!isEditorDirty && !isTitleDirty)}
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptBlogDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteBlogByIdLoading}
          content={{
            body: constructModalBody(blog!.blog_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Blog',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditBlog;
