/* eslint-disable react/react-in-jsx-scope */
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import AddTaskIcon from '@mui/icons-material/AddTask';
// import RememberMeIcon from '@mui/icons-material/RememberMe';
import { MenuItem } from '../SideNavigationSection';

const menuItems: MenuItem[] = [
  {
    key: 1,
    label: 'Users',
    icon: <PersonIcon />,
    path: 'users',
    restrictedTo: ['admin'],
  },
  {
    key: 2,
    label: 'Practitioners',
    icon: <AccountBoxIcon />,
    path: 'practitioners',
    restrictedTo: ['admin', 'editor', 'editorPractitioner'],
  },
  {
    key: 3,
    label: 'Clients',
    icon: <AccountCircleIcon />,
    path: 'clients',
    restrictedTo: ['admin'],
  },
  {
    key: 4,
    label: 'Announcements',
    icon: <NotificationsIcon />,
    path: 'announcements',
    restrictedTo: ['admin'],
  },
  {
    key: 5,
    label: 'Press',
    icon: <NewspaperIcon />,
    path: 'press',
    restrictedTo: ['admin'],
  },
  {
    key: 6,
    label: 'Blogs',
    icon: <ArticleIcon />,
    path: 'blogs',
    restrictedTo: ['admin', 'blogger', 'bloggerPractitioner'],
  },
  // Unused in current build
  // {
  //   key: 7,
  //   label: 'Skills',
  //   icon: <AddTaskIcon />,
  //   path: 'skills',
  //   restrictedTo: ['admin'],
  // },
  // {
  //   key: 8,
  //   label: 'Enrollments',
  //   icon: <RememberMeIcon />,
  //   path: 'enrollments',
  //   restrictedTo: ['admin'],
  // },
];

export default menuItems;
