import Edit from '@mui/icons-material/Edit';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  TableSortLabel,
  TextField,
  Checkbox,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import {
  Order_By,
  Practitioner,
  usePractitionerCountQuery,
  usePractitionersLazyQuery,
  useDeletePractitionerByIdMutation,
  PractitionerDocument,
  PractitionerCountDocument,
} from '../../../api/graphql';
import { commonColumns, defaultTableState } from '../../../common/constants';
import { Column, TableState } from '../../../common/types';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Link from '../../../components/Link';
import { convertOrder } from '../../../common/utils';
import { constructQueryVariables } from './utils';
import Restricted from '../../../components/restricted';

const columns: readonly Column[] = [
  ...commonColumns,
  {
    id: 'location',
    label: 'Location',
    minWidth: 170,
    valuePath: 'profile.location',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    valuePath: 'status',
    format: (value) => (value === 'Verified' ? 'Approved' : 'To Approve'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    valuePath: 'profile.owner.email',
  },
  {
    id: 'display_name',
    label: 'Display Name',
    minWidth: 170,
    valuePath: 'profile.owner.display_name',
  },
  {
    id: 'gender',
    label: 'Gender',
    valuePath: 'profile.owner.gender',
    format: (value) => value.join(', '),
  },
  {
    id: 'is_certified',
    label: 'Certified',
    format: (value) => <Checkbox size="small" checked={value} disabled />,
  },
  {
    id: 'is_trained',
    label: 'Trained',
    format: (value) => <Checkbox size="small" checked={value} disabled />,
  },
  { id: 'actions', label: 'Actions', align: 'right', minWidth: 170 },
];

const constructModalBody = (practitioner?: Practitioner | null) =>
  `Are you sure you want to remove ${practitioner?.profile.owner.display_name}s profile`;

export const PractitionerPage: React.FC = () => {
  const [tableState, setTableState] =
    useState<TableState<Practitioner>>(defaultTableState);

  const { data: count, loading: countLoading } = usePractitionerCountQuery();
  const [fetchPractitioners, { data, loading, called }] =
    usePractitionersLazyQuery();
  const throttledFetchPractitioners = useRef(throttle(fetchPractitioners, 500));

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({ ...tableState, searchTerm: event.target.value });
    const term = event.target.value;
    throttledFetchPractitioners.current({
      variables: constructQueryVariables({ ...tableState, searchTerm: term }),
    });
  };

  useEffect(() => {
    fetchPractitioners({
      variables: constructQueryVariables(tableState),
    });
  }, [fetchPractitioners]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = tableState.orderBy === property && tableState.order === 'asc';
    const nextOrder = isAsc ? Order_By.Desc : Order_By.Asc;
    setTableState({ ...tableState, order: nextOrder, orderBy: property });
    fetchPractitioners({
      variables: constructQueryVariables({
        ...tableState,
        order: nextOrder,
        orderBy: property,
      }),
    });
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const [
    deletePractitionerRequest,
    { loading: deletePractitionerByIdLoading },
  ] = useDeletePractitionerByIdMutation({
    onCompleted: () => {
      setTableState({ ...tableState, isRemovingItem: false });
    },
    onError: () => {
      setTableState({ ...tableState, isRemovingItem: false });
    },
    refetchQueries: [
      PractitionerDocument,
      'practitioners',
      PractitionerCountDocument,
      'practitionerCount',
    ],
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableState({ ...tableState, page: newPage });
    fetchPractitioners({
      variables: constructQueryVariables({ ...tableState, page: newPage }),
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableState({ ...tableState, rowsPerPage: +event.target.value, page: 0 });
    fetchPractitioners({
      variables: constructQueryVariables({
        ...tableState,
        rowsPerPage: +event.target.value,
        page: 0,
      }),
    });
  };

  const deletePractitioner = (practitioner: Practitioner): void => {
    setTableState({
      ...tableState,
      isRemovingItem: true,
      removableItem: practitioner,
    });
  };

  const onDeleteApprove = () => {
    deletePractitionerRequest({
      variables: { id: tableState.removableItem!.id },
    });
  };
  const onDeleteDeny = () =>
    setTableState({ ...tableState, isRemovingItem: false });
  const onDeleteClose = () =>
    setTableState({ ...tableState, isRemovingItem: false });

  return (
    <>
      <Link to="create">
        <Button fullWidth variant="contained" sx={{ marginBottom: 2 }}>
          Create Practitioner
        </Button>
      </Link>
      <TextField
        id="search"
        label="Search"
        fullWidth
        value={tableState.searchTerm}
        onChange={onSearchChange}
        sx={{ marginBottom: 2 }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'calc(100vh - 20px)', maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={tableState.orderBy === column.id}
                        direction={
                          tableState.orderBy === column.id
                            ? convertOrder(tableState.order)
                            : 'asc'
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && called
                ? data!.practitioner_aggregate.nodes.map((row: any) => {
                    // Fix this any later
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={
                          Object.keys(row.fields_to_approve).length !== 0 ||
                          row.status === 'Unverified'
                            ? { backgroundColor: 'red' }
                            : {}
                        }
                      >
                        {columns.map((column) => {
                          const value = column.valuePath
                            ? get(row, column.valuePath)
                            : row[column.id];
                          const formattedValue = column.format
                            ? column.format(value)
                            : value;
                          if (column.id === 'actions')
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Link to={`edit/${row.id}`}>
                                  <IconButton aria-label="edit-item">
                                    <Edit />
                                  </IconButton>
                                </Link>
                                <Restricted to={['admin']}>
                                  <IconButton
                                    aria-label="delete-item"
                                    onClick={() => deletePractitioner(row)}
                                  >
                                    <DeleteOutlined />
                                  </IconButton>
                                </Restricted>
                              </TableCell>
                            );

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.children
                                ? column.children(formattedValue)
                                : formattedValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {countLoading ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count!.practitioner_aggregate.aggregate!.count}
            rowsPerPage={tableState.rowsPerPage}
            page={tableState.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <ConfirmationModal
          open={tableState.isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deletePractitionerByIdLoading}
          content={{
            body: constructModalBody(tableState.removableItem),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Practitioner',
          }}
        />
      </Paper>
    </>
  );
};

export default PractitionerPage;
